import React from 'react'

const SectionHeader = ({
  title,
  children,
  border = true,
  subheader = false,
}) => (
  <React.Fragment>
    <div className="flex justify-between items-center mt2">
      {subheader ? <h2>{title}</h2> : <h1>{title}</h1>}
      <div className="flex justify-end">{children}</div>
    </div>
    {border && <div className="borderTop mb3" />}
  </React.Fragment>
)

const Section = ({ children }) => <section className="mb3">{children}</section>

export { Section }
export default SectionHeader
