import React from 'react'
import Container from 'components/Container'
import Divider from '@material-ui/core/Divider'
import { PrimaryButton, OutlinedButton } from 'components/Button'
import cx from 'classnames'

export const PADDING = 140

export const ActionSheetContainer = ({ children }) => (
  <div
    style={{
      position: 'fixed',
      bottom: 0,
      width: '100%',
      background: 'white',
    }}>
    <Container>
      <Divider style={{ position: 'absolute', left: 0, width: '100%' }} />
      <div
        style={{
          padding: '1.5em 0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}>
        {children}
      </div>
    </Container>
  </div>
)

const ActionSheet = ({
  primaryText,
  primaryProps,
  onPrimary,
  secondaryText,
  onSecondary,
  onCancel,
  cancelText = 'Cancel',
  secondaryProps,
}) => (
  <ActionSheetContainer>
    <div
      className={cx(
        'flex',
        'items-center',
        'flex-auto',
        onCancel ? 'justify-between' : 'justify-end',
      )}>
      {onCancel && (
        <span
          onClick={onCancel}
          style={{
            textTransform: 'uppercase',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}>
          {cancelText}
        </span>
      )}
      <div>
        {secondaryText && (
          <OutlinedButton
            size="large"
            onClick={onSecondary}
            {...secondaryProps}>
            {secondaryText}
          </OutlinedButton>
        )}
        <PrimaryButton
          size="large"
          onClick={onPrimary}
          {...primaryProps}
          style={{ marginLeft: '1em' }}>
          {primaryText}
        </PrimaryButton>
      </div>
    </div>
  </ActionSheetContainer>
)

export default ActionSheet
