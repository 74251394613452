import * as Constants from './constants'

export const validateToken = () => ({
  type: Constants.VALIDATE_TOKEN,
})

export const validateTokenSuccess = ({ user }) => ({
  type: Constants.VALIDATE_TOKEN_SUCCESS,
  payload: {
    user,
  },
})

export const validateTokenFailure = error => ({
  type: Constants.VALIDATE_TOKEN_FAILURE,
  payload: {
    error,
  },
})

export const signIn = ({ email, password }) => ({
  type: Constants.SIGN_IN,
  payload: {
    email,
    password,
  },
})

export const signInSuccess = ({ user }) => ({
  type: Constants.SIGN_IN_SUCCESS,
  payload: {
    user,
  },
})

export const signInFailure = error => ({
  type: Constants.SIGN_IN_FAILURE,
  payload: {
    error,
  },
})

export const signOut = () => ({
  type: Constants.SIGN_OUT,
})

export const signOutSuccess = () => ({
  type: Constants.SIGN_OUT_SUCCESS,
})

export const signOutFailure = error => ({
  type: Constants.SIGN_OUT_FAILURE,
  payload: {
    error,
  },
})
