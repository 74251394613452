import * as Constants from './constants'
import { combineReducers } from 'redux'

const sidebarOpen = (state = false, action) => {
  switch (action.type) {
    case Constants.OPEN_SIDEBAR:
      return true
    case Constants.CLOSE_SIDEBAR:
      return false
    default:
      return state
  }
}

const confirmationOpen = (state = false, action) => {
  switch (action.type) {
    case Constants.SHOW_CONFIRMATION:
      return true
    case Constants.CLOSE_CONFIRMATION:
      return false
    default:
      return state
  }
}

const confirmationMessage = (state = {}, action) => {
  switch (action.type) {
    case Constants.SHOW_CONFIRMATION:
      return action.payload
    case Constants.CLOSE_CONFIRMATION:
      return {}
    default:
      return state
  }
}

const orderSubmittedOpen = (state = false, action) => {
  switch (action.type) {
    case Constants.OPEN_ORDER_SUBMITTED:
      return true
    case Constants.CLOSE_ORDER_SUBMITTED:
      return false
    default:
      return state
  }
}

const editMeasurementsOpen = (state = false, action) => {
  switch (action.type) {
    case Constants.OPEN_EDIT_MEASUREMENTS:
      return true
    case Constants.CLOSE_EDIT_MEASUREMENTS:
      return false
    default:
      return state
  }
}

const editShirtOpen = (state = false, action) => {
  switch (action.type) {
    case Constants.OPEN_EDIT_SHIRT:
      return true
    case Constants.CLOSE_EDIT_SHIRT:
      return false
    default:
      return state
  }
}

const editNotesOpen = (state = false, action) => {
  switch (action.type) {
    case Constants.OPEN_EDIT_NOTES:
      return true
    case Constants.CLOSE_EDIT_NOTES:
      return false
    default:
      return state
  }
}

export default combineReducers({
  sidebarOpen,
  confirmationOpen,
  confirmationMessage,
  orderSubmittedOpen,
  editMeasurementsOpen,
  editShirtOpen,
  editNotesOpen,
})
