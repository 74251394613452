export const getIsSidebarOpen = state => state.ui.sidebarOpen
export const getIsConfirmationOpen = state => state.ui.confirmationOpen
export const getConfirmationMessage = state => state.ui.confirmationMessage

export const getIsOrderSubmittedOpen = state => state.ui.orderSubmittedOpen

export const getIsEditMeasurementsOpen = state => state.ui.editMeasurementsOpen

export const getIsEditShirtOpen = state => state.ui.editShirtOpen

export const getIsEditNotesOpen = state => state.ui.editNotesOpen
