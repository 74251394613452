import { connect } from 'react-redux'
import { Selectors as ClientSelectors } from 'state/clients'
import { Selectors as OrderSelectors } from 'state/orders'

const mapStateToProps = state => ({
  open:
    ClientSelectors.getIsUpdating(state) ||
    ClientSelectors.getIsCreating(state) ||
    OrderSelectors.getIsLoading(state) ||
    OrderSelectors.getIsCreating(state) ||
    OrderSelectors.getIsUpdating(state) ||
    OrderSelectors.getIsSubmittingForReview(state) ||
    OrderSelectors.getIsSubmittingToManufacturer(state) ||
    OrderSelectors.getIsDuplicating(state) ||
    OrderSelectors.getIsCanceling(state),
})

export default connect(mapStateToProps)
