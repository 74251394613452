import React from 'react'
import { connect } from 'react-redux'
import {
  Actions as ClientActions,
  Selectors as ClientSelectors,
} from 'state/clients'
import { Route } from 'react-router-dom'
import EditClientPage from 'pages/EditClientPage'
import ClientShowPage from 'pages/ClientShowPage'

const mapStateToProps = (state, { match }) => ({
  client: ClientSelectors.getMember(state, match.params.id),
})

const mapDispatchToProps = {
  loadClient: ClientActions.load,
}

class ClientRoutes extends React.Component {
  componentDidMount() {
    this.props.loadClient(this.props.match.params.id)
  }
  render() {
    const { match, client } = this.props
    if (!client) return null

    const passProps = {
      client,
    }

    return (
      <React.Fragment>
        <Route
          exact
          path={match.url}
          render={props => <ClientShowPage {...props} {...passProps} />}
        />
        <Route
          path={`${match.url}/edit`}
          exact
          render={props => <EditClientPage {...props} {...passProps} />}
        />
      </React.Fragment>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientRoutes)
