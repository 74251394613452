import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  container: {
    maxWidth: '90%',
    margin: '0 auto',
  },
})

const Container = ({ children, style, classes, className }) => (
  <div style={style} className={classes.container}>
    {children}
  </div>
)

export default withStyles(styles)(Container)
