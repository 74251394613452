import { connect } from 'react-redux'
import { Actions as UIActions, Selectors as UISelectors } from 'state/ui'

const mapStateToProps = state => ({
  open: UISelectors.getIsConfirmationOpen(state),
  title: UISelectors.getConfirmationMessage(state).title,
  message: UISelectors.getConfirmationMessage(state).message,
  primaryText: UISelectors.getConfirmationMessage(state).primaryText,
  secondaryText: UISelectors.getConfirmationMessage(state).secondaryText,
})

const mapDispatchToProps = {
  onConfirm: UIActions.confirmYes,
  onCancel: UIActions.confirmNo,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)
