export const OPEN_SIDEBAR = 'UI/OPEN_SIDEBAR'
export const CLOSE_SIDEBAR = 'UI/CLOSE_SIDEBAR'

export const SHOW_CONFIRMATION = 'UI/SHOW_CONFIRMATION'
export const CLOSE_CONFIRMATION = 'UI/CLOSE_CONFIRMATION'

export const CONFIRM_YES = 'UI/CONFIRM_YES'
export const CONFIRM_NO = 'UI/CONFIRM_NO'

export const OPEN_ORDER_SUBMITTED = 'UI/OPEN_ORDER_SUBMITTED'
export const CLOSE_ORDER_SUBMITTED = 'UI/CLOSE_ORDER_SUBMITTED'

export const OPEN_EDIT_MEASUREMENTS = 'UI/OPEN_EDIT_MEASUREMENTS'
export const CLOSE_EDIT_MEASUREMENTS = 'UI/CLOSE_EDIT_MEASUREMENTS'

export const OPEN_EDIT_SHIRT = 'UI/OPEN_EDIT_SHIRT'
export const CLOSE_EDIT_SHIRT = 'UI/CLOSE_EDIT_SHIRT'

export const OPEN_EDIT_NOTES = 'UI/OPEN_EDIT_NOTES'
export const CLOSE_EDIT_NOTES = 'UI/CLOSE_EDIT_NOTES'
