export const getIsLoading = state => state.orders.isLoading
export const getIsCreating = state => state.orders.isCreating
export const getIsUpdating = state => state.orders.isUpdating
export const getIsSubmittingForReview = state =>
  state.orders.isSubmittingForReview
export const getIsSubmittingToManufacturer = state =>
  state.orders.isSubmittingToManufacturer
export const getIsDuplicating = state => state.orders.isDuplicating
export const getIsCanceling = state => state.orders.isRemoving

export const getById = state => state.orders.byId
export const getOrder = (state, id) => state.orders.byId[id]
export const getAllForClient = (state, clientId) =>
  Object.values(state.orders.byId).filter(o => o.client_id === Number(clientId))

export const getRecent = state =>
  Object.values(state.orders.byId).sort(o => o.created_at)

export const getOpen = state =>
  Object.values(state.orders.byId)
    .filter(o => !o.submitted_for_review_at && !o.submitted_to_manufacturer_at)
    .sort(o => o.created_at)

export const getSubmitted = state =>
  Object.values(state.orders.byId)
    .filter(o => !!o.submitted_to_manufacturer_at)
    .sort(o => o.submitted_to_manufacturer_at)

export const getReview = state =>
  Object.values(state.orders.byId)
    .filter(
      o => !!o.submitted_for_review_at && !!!o.submitted_to_manufacturer_at,
    )
    .sort(o => o.submitted_for_review_at)

export const getUnsubmittedOrders = (state, clientId) => {
  return getAllForClient(state, clientId).filter(
    o => !o.submitted_for_review_at && !o.submitted_to_manufacturer_at,
  )
}

export const getSearch = state => state.orders.search
