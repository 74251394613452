import { all } from 'redux-saga/effects'
import { sagas as clients } from './clients'
import { sagas as auth } from './auth'
import { sagas as measurements } from './measurements'
import { sagas as garmentTypes } from './garmentTypes'
import { sagas as orders } from './orders'
import { sagas as ui } from './ui'

export default function* root() {
  yield all([clients(), auth(), measurements(), garmentTypes(), orders(), ui()])
}
