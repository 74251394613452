import * as Constants from './constants'

export const load = id => ({
  type: Constants.LOAD,
  payload: {
    id,
  },
})

export const loadPending = () => ({
  type: Constants.LOAD_PENDING,
})

export const loadSuccess = ({ client }) => ({
  type: Constants.LOAD_SUCCESS,
  payload: {
    client,
  },
})

export const loadFailure = error => ({
  type: Constants.LOAD_FAILURE,
  payload: {
    error,
  },
})

export const loadAll = query => ({
  type: Constants.LOAD_ALL,
  payload: {
    query,
  },
})

export const loadAllPending = () => ({
  type: Constants.LOAD_ALL_PENDING,
})

export const loadAllSuccess = ({
  clients,
  pageCount,
  page,
  perPage,
  total,
  nextPage,
  prevPage,
  lastPage,
}) => ({
  type: Constants.LOAD_ALL_SUCCESS,
  payload: {
    clients,
    pageCount,
    page,
    perPage,
    total,
    nextPage,
    prevPage,
    lastPage,
  },
})

export const loadAllFailure = error => ({
  type: Constants.LOAD_ALL_FAILURE,
  payload: {
    error,
  },
})

export const create = params => ({
  type: Constants.CREATE,
  payload: {
    params,
  },
})

export const createPending = () => ({
  type: Constants.CREATE_PENDING,
})

export const createSuccess = ({ client }) => ({
  type: Constants.CREATE_SUCCESS,
  payload: {
    client,
  },
})

export const createFailure = error => ({
  type: Constants.CREATE_FAILURE,
  payload: {
    error,
  },
})

export const search = query => ({
  type: Constants.SEARCH,
  payload: {
    query,
  },
})

export const searchSuccess = ({ query, clients }) => ({
  type: Constants.SEARCH_SUCCESS,
  payload: {
    query,
    clients,
  },
})

export const searchFailure = error => ({
  type: Constants.SEARCH_FAILURE,
  payload: {
    error,
  },
})

export const update = ({ clientId, transitionTo, params }) => ({
  type: Constants.UPDATE,
  payload: {
    clientId,
    transitionTo,
    params,
  },
})

export const updatePending = () => ({
  type: Constants.UPDATE_PENDING,
})

export const updateSuccess = ({ client }) => ({
  type: Constants.UPDATE_SUCCESS,
  payload: {
    client,
  },
})

export const updateFailure = error => ({
  type: Constants.UPDATE_FAILURE,
  payload: {
    error,
  },
})

export const updateMeasurements = ({ clientId, measurements }) => ({
  type: Constants.UPDATE_MEASUREMENTS,
  payload: { clientId, measurements },
})

export const updateMeasurementsSuccess = ({ client }) => ({
  type: Constants.UPDATE_MEASUREMENTS_SUCCESS,
  payload: {
    client,
  },
})

export const updateMeasurementsFailure = error => ({
  type: Constants.UPDATE_MEASUREMENTS_FAILURE,
  payload: {
    error,
  },
})
