export const LOAD = 'Measurements/LOAD'
export const LOAD_SUCCESS = 'Measurements/LOAD_SUCCESS'
export const LOAD_FAILURE = 'Measurements/LOAD_FAILURE'

export const CREATE = 'Measurements/CREATE'
export const CREATE_SUCCESS = 'Measurements/CREATE_SUCCESS'
export const CREATE_FAILURE = 'Measurements/CREATE_FAILURE'

export const UPDATE = 'Measurements/UPDATE'
export const UPDATE_SUCCESS = 'Measurements/UPDATE_SUCCESS'
export const UPDATE_FAILURE = 'Measurements/UPDATE_FAILURE'

export const REQUEST_SET_TAB = 'Measurements/REQUEST_SET_TAB'
export const SET_TAB = 'Measurements/SET_TAB'

export const UPDATING = 'Measurements/UPDATING'
