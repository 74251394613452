export const VALIDATE_TOKEN = 'Auth/VALIDATE_TOKEN'
export const VALIDATE_TOKEN_SUCCESS = 'Auth/VALIDATE_TOKEN_SUCCESS'
export const VALIDATE_TOKEN_FAILURE = 'Auth/VALIDATE_TOKEN_FAILURE'

export const SIGN_IN = 'Auth/SIGN_IN'
export const SIGN_IN_SUCCESS = 'Auth/SIGN_IN_SUCCESS'
export const SIGN_IN_FAILURE = 'Auth/SIGN_IN_FAILURE'

export const SIGN_OUT = 'Auth/SIGN_OUT'
export const SIGN_OUT_SUCCESS = 'Auth/SIGN_OUT_SUCCESS'
export const SIGN_OUT_FAILURE = 'Auth/SIGN_OUT_FAILURE'
