import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Container from 'components/Container'
import { OutlinedButton } from 'components/Button'
import OrderSearch from 'components/OrderSearch'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Avatar from 'react-avatar'
import ChevronRight from '@material-ui/icons/ChevronRight'
import moment from 'moment'
import CreateOrderModal from 'components/CreateOrderModal'
import SectionHeader from 'components/common/SectionHeader'
import { take, sortBy } from 'lodash'

const styles = theme => ({
  hero: {
    height: 300,
    background: 'lightgrey',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/login.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 55%',
  },
  button: {
    color: 'white',
    borderColor: 'white',
    '&:hover': {
      color: 'white',
      borderColor: 'white',
    },
  },
  avatar: {
    borderRadius: 0,
    height: 100,
    width: 100,
  },
  overflow: {
    width: '80%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})

const getFabricMill = order => {
  if (!order) return ''
  const vendor = order.garment_summary.find(
    o => o.option_display === 'Fabric Vendor',
  )
  if (!vendor) return 'Mill: TBD'
  return `Mill: ${vendor.value_display}`
}

const getFabricNumber = order => {
  if (!order) return ''
  const number = order.garment_summary.find(
    o => o.option_display === 'Fabric Number',
  )
  if (!number || !number.value_display) return 'Fabric Number: TBD'
  return `Fabric Number: ${number.value_display}`
}

const OrderSection = ({ title, orders, classes, link }) => (
  <React.Fragment>
    <SectionHeader title={title} border={false}>
      <Link to={link}>View All</Link>
    </SectionHeader>
    {orders.length > 0 ? (
      <List className="borderTop">
        {take(orders, 3).map(order => (
          <ListItem
            button
            component={Link}
            to={`/clients/${order.client_id}/orders/${order.id}`}
            key={order.id}
            divider>
            <ListItemAvatar>
              <Avatar
                className="avatar"
                name={order.client.full_name}
                src={order.client.avatar}
                size={100}
                color="#D8D8D8"
                fgColor="black"
              />
            </ListItemAvatar>
            <ListItemText
              primary={order.order_number}
              disableTypography
              secondary={
                <div>
                  <div>{moment(order.created_at).format('MM/DD/YY')}</div>
                  <div className={classes.overflow}>
                    {order.client.full_name}
                  </div>
                  <div>{order.order_number}</div>
                  <div>
                    {getFabricMill(order)} / {getFabricNumber(order)}
                  </div>
                </div>
              }
            />
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    ) : (
      <p>No Orders to Display</p>
    )}
  </React.Fragment>
)

class Dashboard extends React.Component {
  state = {
    modalOpen: false,
  }

  componentDidMount() {
    this.props.loadOrders()
  }

  handleClickOpen = () => {
    this.setState({ modalOpen: true })
  }

  handleClose = () => {
    this.setState({ modalOpen: false })
  }

  render() {
    const { submittedOrders, reviewOrders, openOrders, classes } = this.props
    return (
      <div>
        <CreateOrderModal
          isOpen={this.state.modalOpen}
          onClose={this.handleClose}
        />
        <div className={classes.hero}>
          <OutlinedButton
            onClick={this.handleClickOpen}
            className={classes.button}>
            Create New Order
          </OutlinedButton>
        </div>
        <Container>
          <div className="mt3">
            <OrderSearch />
          </div>
          <OrderSection
            orders={sortBy(reviewOrders, 'submitted_for_review_at').reverse()}
            title="Orders Ready For Review"
            classes={classes}
            link={`/orders?status=review`}
          />
          <OrderSection
            orders={sortBy(
              submittedOrders,
              'submitted_to_manufacturer_at',
            ).reverse()}
            title="Submitted Orders"
            classes={classes}
            link={`/orders?status=submitted`}
          />
          <OrderSection
            orders={sortBy(openOrders, 'created_at').reverse()}
            title="Open Orders"
            classes={classes}
            link={`/orders?status=open`}
          />
        </Container>
      </div>
    )
  }
}

export default withStyles(styles)(Dashboard)
