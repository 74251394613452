import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import { OutlinedButton } from 'components/Button'
import { Link } from 'react-router-dom'

function Transition(props) {
  return <Slide direction="up" {...props} />
}

const NewClientLink = props => <Link to="/clients/new" {...props} />
const ClientSearchLink = props => <Link to="/clients/search" {...props} />

const styles = {
  root: {
    width: 500,
  },
  content: {
    padding: 0,
  },
  heading: {
    fontSize: '1.5em',
    textAlign: 'center',
    margin: 0,
  },
  image: {
    backgroundImage: 'url(/new-client.jpg)',
    height: 175,
    backgroundPosition: '50% 30%',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image2: {
    backgroundImage: 'url(/existing-client.jpg)',
    height: 175,
    backgroundPosition: '50% 20%',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    filter: 'grayscale(100%)',
  },
  button: {
    color: 'white',
    borderColor: 'white',
    '&:hover': {
      color: 'white',
      borderColor: 'white',
    },
  },
  subtext: {
    fontSize: '.875rem',
    textAlign: 'center',
    margin: 0,
  },
}

class AlertDialogSlide extends React.Component {
  render() {
    const { isOpen, onClose, classes } = this.props
    return (
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        maxWidth="lg"
        classes={{
          paper: classes.root,
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle id="alert-dialog-slide-title" disableTypography>
          <h1 className={classes.heading}>Create New Order</h1>
          <p className={classes.subtext}>
            Is this for a new or existing client?
          </p>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <div className={classes.image}>
            <OutlinedButton
              component={NewClientLink}
              className={classes.button}>
              New Client
            </OutlinedButton>
          </div>
          <div className={classes.image2}>
            <OutlinedButton
              component={ClientSearchLink}
              className={classes.button}>
              Existing Client
            </OutlinedButton>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

export default withStyles(styles)(AlertDialogSlide)
