import React from 'react'
import Container from 'components/Container'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Avatar from 'react-avatar'
import { isEmpty } from 'lodash'
import SectionHeader, { Section } from 'components/common/SectionHeader'
import ActionLink from 'components/common/ActionLink'
import MeasurementSummary from 'components/MeasurementSummary'
import EditMeasurementsModal from 'components/EditMeasurementsModal'
import ImageOption from 'components/common/ImageOption'
import ActionSheet from 'components/common/ActionSheet'
import moment from 'moment'
import EditShirtModal from 'components/EditShirtModal'
import EditNotesModal from 'components/EditNotesModal'
import { getStateAbbrev } from 'utils/helpers'

const OrderImage = ({ label, src }) =>
  src ? (
    <Avatar
      className="avatar"
      size={175}
      color="#D8D8D8"
      fgColor="black"
      textSizeRatio={1.75}
      style={{ fontSize: '1em', marginBottom: 8 }}
      value={label}
      src={src}
    />
  ) : (
    <div
      style={{
        height: 200,
        width: 200,
        marginBottom: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
      }}>
      {label}
    </div>
  )

const OrderImages = ({ order }) => (
  <Grid container spacing={24}>
    <Grid item xs={6} sm={4} md={3}>
      <OrderImage label="Front Image" src={order.front_image} />
    </Grid>
    <Grid item xs={6} sm={4} md={3}>
      <OrderImage label="Side Image" src={order.side_image} />
    </Grid>
    <Grid item xs={6} sm={4} md={3}>
      <OrderImage label="Back Image" src={order.back_image} />
    </Grid>
    <Grid item xs={6} sm={4} md={3}>
      <OrderImage label="Additional Image" src={order.additional_image} />
    </Grid>
  </Grid>
)

const GarmentOptions = ({ garment }) => {
  return (
    <Grid container>
      {garment.map((option, index) => (
        <Grid item xs={6} sm={4} md={3} key={index}>
          <h3 className="mb1">{option.option_display}</h3>
          <ImageOption display={option.value_display} imageUrl={option.image} />
        </Grid>
      ))}
    </Grid>
  )
}

const OrderStatus = ({ status }) => {
  const color =
    status === 'Submitted'
      ? '#658355'
      : status === 'Ready for Review'
        ? '#724B4E'
        : '#9A9A9A'
  return (
    <p
      style={{
        borderRadius: 5,
        border: '2px solid black',
        borderColor: color,
        padding: '2px 8px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: 14,
        color,
      }}>
      {status}
    </p>
  )
}

class OrderPage extends React.Component {
  state = {
    editingOrder: null,
  }

  canEdit = () => {
    const { isAdmin, order } = this.props
    if (!!order.submitted_to_manufacturer_at) return false
    return isAdmin || !order.submitted_for_review_at
  }

  canSubmitToManufacturer = () => {
    const { isAdmin, order } = this.props
    return isAdmin && !!order.submitted_for_review_at
  }

  canViewPdf = () => {
    const { order } = this.props
    return !!order.submitted_for_review_at
  }

  handleSubmitToManufacturer = () => {
    this.props.submitToManufacturer({ orderId: this.props.order.id })
  }

  handleSubmitForReview = () => {
    this.props.submitForReview({ orderId: this.props.order.id })
  }

  handleCancelOrder = () => {
    this.props.cancelOrder(this.props.order.id)
  }

  getPreviewURL = () => {
    const { order } = this.props
    return process.env.NODE_ENV === 'production'
      ? `https://damp-beach-23575.herokuapp.com/orders/${order.id}/pdf`
      : `http://localhost:3000/orders/${order.id}/pdf`
  }

  renderSubmissionDates = () => {
    const { order } = this.props
    return (
      <React.Fragment>
        {order.submitted_to_manufacturer_at && (
          <li className="mb1">
            Submitted to Manufacturer on{' '}
            {moment(order.submitted_to_manufacturer_at).format('MM/DD/YY')}
          </li>
        )}
        {order.submitted_for_review_at && (
          <li className="mb1">
            Submitted for Review on{' '}
            {moment(order.submitted_for_review_at).format('MM/DD/YY')}
          </li>
        )}
      </React.Fragment>
    )
  }

  handleEditShirt = () => {
    this.props.openEditShirt()
  }

  handleEditNotes = () => {
    this.props.openEditNotes()
  }

  render() {
    const { order, client } = this.props
    if (!order || !client) return null

    return (
      <div style={{ marginBottom: 160 }}>
        <EditMeasurementsModal order={order} client={client} />
        <EditShirtModal order={order} />
        <EditNotesModal order={order} />
        <Container>
          <Section>
            <SectionHeader title={`Order #${order.order_number}`} border>
              <OrderStatus status={order.status} />
            </SectionHeader>
          </Section>
          <Grid container>
            <Grid item xs={12} sm={3} className="center">
              <Avatar
                src={client.avatar}
                name={client.full_name}
                className="avatar"
                size={200}
                color="#D8D8D8"
                fgColor="black"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="ml3">
                <Link to={`/clients/${client.id}`}>
                  <h3 style={{ textTransform: 'capitalize' }}>
                    {client.full_name}
                  </h3>
                </Link>
                <ul className="list-reset ml3" style={{ listStyle: 'disc' }}>
                  {this.renderSubmissionDates()}
                  <li className="mb1">Stylist: {order.created_by}</li>
                  {client.email && <li className="mb1">{client.email}</li>}
                  {client.phone && <li className="mb1">{client.phone}</li>}
                  {client.street_address && (
                    <li className="mb1">
                      <div>{client.street_address}</div>
                      <div>{client.street_address2}</div>
                      <div>
                        {client.city &&
                          client.state &&
                          `${client.city}, ${getStateAbbrev(client.state)}`}
                        {client.zipcode}
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </Grid>
          </Grid>
          <Container>
            <Section>
              <SectionHeader title="Measurement & Fit" subheader>
                {this.canEdit() && (
                  <ActionLink onClick={this.props.openEditMeasurements}>
                    Edit
                  </ActionLink>
                )}
              </SectionHeader>
              <MeasurementSummary
                measurements={
                  isEmpty(order.measurement_snapshot)
                    ? client.measurements
                    : order.measurement_snapshot
                }
                showFinished
                fitType={order.fitting_type}
              />
            </Section>
            <Section>
              <OrderImages order={order} />
            </Section>
            <Section>
              <SectionHeader title="Shirt Design" subheader>
                {this.canEdit() && (
                  <ActionLink onClick={this.handleEditShirt}>Edit</ActionLink>
                )}
              </SectionHeader>
              {order.garment_summary && (
                <GarmentOptions garment={order.garment_summary} />
              )}
            </Section>
            <Section>
              <SectionHeader title="Notes" subheader>
                {this.canEdit() && (
                  <ActionLink onClick={this.handleEditNotes}>Edit</ActionLink>
                )}
              </SectionHeader>
              <p>{order.notes || 'None'}</p>
            </Section>
          </Container>
        </Container>
        {this.canSubmitToManufacturer() ? (
          <ActionSheet
            primaryText="Submit To Manufacturer"
            onPrimary={this.handleSubmitToManufacturer}
            secondaryText="Preview PDF"
            secondaryProps={{
              component: 'a',
              href: this.getPreviewURL(),
              target: '_window',
            }}
            cancelText="Cancel Order"
            onCancel={this.handleCancelOrder}
          />
        ) : this.canEdit() ? (
          <ActionSheet
            primaryText="Submit For Review"
            onPrimary={this.handleSubmitForReview}
            cancelText="Cancel Order"
            onCancel={this.handleCancelOrder}
          />
        ) : null}
      </div>
    )
  }
}

export default OrderPage
