import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import cx from 'classnames'
import styles from './styles'

const CustomInput = ({ classes, label, children, meta = {}, ...rest }) => {
  const { touched, error, warning } = meta
  return (
    <React.Fragment>
      {label && (
        <InputLabel shrink className={classes.bootstrapFormLabel}>
          {label}
        </InputLabel>
      )}
      <InputBase
        classes={{
          root: classes.bootstrapRoot,
          input: cx(classes.bootstrapInput, touched && error && classes.error),
        }}
        {...rest}
      />
      {(error || warning) && (
        <div className={classes.errorMessage}>
          {touched &&
            ((error && <span>{error}</span>) ||
              (warning && <span>{warning}</span>))}
        </div>
      )}
    </React.Fragment>
  )
}

export default withStyles(styles)(CustomInput)
