import * as Constants from './constants'
import * as Actions from './actions'
import xhr from 'xhr'
import { takeLatest, all, call, put } from 'redux-saga/effects'

export function* load() {
  try {
    const { data } = yield call(xhr, {
      method: 'get',
      url: '/garment_types',
    })

    yield put(Actions.loadSuccess({ garmentTypes: data }))
  } catch (error) {
    yield put(Actions.loadFailure(error))
  }
}

export default function* Measurements() {
  yield all([takeLatest(Constants.LOAD, load)])
}
