import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Selectors as AuthSelectors, Actions as AuthActions } from 'state/auth'

class AuthWrap extends React.Component {
  componentWillMount() {
    this.props.validateToken()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isSignedIn && !this.props.isSignedIn) {
      this.props.history.push('/signin')
    }
  }

  render() {
    if (this.props.isValidatingToken) return null
    if (!this.props.isValidatingToken && !this.props.isSignedIn) {
      this.props.history.push('/signin')
    }
    return this.props.children
  }
}

const mapStateToProps = state => ({
  isSignedIn: AuthSelectors.getIsSignedIn(state),
  isValidatingToken: AuthSelectors.getIsValidatingToken(state),
})

const mapDispatchToProps = {
  validateToken: AuthActions.validateToken,
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AuthWrap),
)
