import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from 'react-router-dom'
import store, { history } from './store'
import SignInPage from 'pages/SignInPage'
import CreateClientPage from 'pages/CreateClientPage'
import CartPage from 'pages/CartPage'
import CssBaseline from '@material-ui/core/CssBaseline'
import Navbar from 'components/Navbar'
import { MuiThemeProvider } from '@material-ui/core/styles'
import theme from './theme'
import AuthWrap from 'components/AuthWrap'
import Dashboard from 'pages/Dashboard'
import OrderListPage from 'pages/OrderListPage'
import ClientSearchPage from 'pages/ClientSearchPage'
import Drawer from 'components/Drawer'
import ScrollToTop from 'components/ScrollToTop'
import Confirmation from 'components/Confirmation'
import OrderSubmittedDialog from 'components/OrderSubmittedDialog'
import Loading from 'components/Loading'
import ClientRoutes from 'routes/ClientRoutes'
import OrderRoutes from 'routes/OrderRoutes'

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <MuiThemeProvider theme={theme}>
            <ScrollToTop>
              <CssBaseline />
              <Switch>
                <Route path="/signin" component={SignInPage} />
                <AuthWrap>
                  <Navbar />
                  <div style={{ height: 64 }} />
                  <Drawer />
                  <Confirmation />
                  <OrderSubmittedDialog />
                  <Loading />
                  <Switch>
                    <Route
                      exact
                      path="/clients/search"
                      component={ClientSearchPage}
                    />
                    <Route
                      exact
                      path="/clients/new"
                      component={CreateClientPage}
                    />
                    <Route
                      exact
                      path="/clients/:id/orders/review"
                      component={CartPage}
                    />
                    <Route
                      path="/clients/:id/orders/:orderId"
                      component={OrderRoutes}
                    />
                    <Route path="/clients/:id" component={ClientRoutes} />
                    <Route path="/orders" component={OrderListPage} />
                    <Route exact path="/" component={Dashboard} />
                  </Switch>
                </AuthWrap>
              </Switch>
            </ScrollToTop>
          </MuiThemeProvider>
        </ConnectedRouter>
      </Provider>
    )
  }
}
