import * as Constants from './constants'

export const openSidebar = () => ({
  type: Constants.OPEN_SIDEBAR,
  payload: {},
})

export const closeSidebar = () => ({
  type: Constants.CLOSE_SIDEBAR,
  payload: {},
})

export const showConfirmation = ({
  title,
  message,
  primaryText,
  secondaryText,
}) => ({
  type: Constants.SHOW_CONFIRMATION,
  payload: {
    title,
    message,
    primaryText,
    secondaryText,
  },
})

export const closeConfirmation = () => ({
  type: Constants.CLOSE_CONFIRMATION,
})

export const confirmYes = () => ({
  type: Constants.CONFIRM_YES,
})

export const confirmNo = () => ({
  type: Constants.CONFIRM_NO,
})

export const openOrderSubmitted = () => ({
  type: Constants.OPEN_ORDER_SUBMITTED,
})

export const closeOrderSubmitted = () => ({
  type: Constants.CLOSE_ORDER_SUBMITTED,
})

export const openEditMeasurements = () => ({
  type: Constants.OPEN_EDIT_MEASUREMENTS,
})

export const closeEditMeasurements = () => ({
  type: Constants.CLOSE_EDIT_MEASUREMENTS,
})

export const openEditShirt = () => ({
  type: Constants.OPEN_EDIT_SHIRT,
})

export const closeEditShirt = () => ({
  type: Constants.CLOSE_EDIT_SHIRT,
})

export const openEditNotes = () => ({
  type: Constants.OPEN_EDIT_NOTES,
})

export const closeEditNotes = () => ({
  type: Constants.CLOSE_EDIT_NOTES,
})
