import { connect } from 'react-redux'
import { Actions as AuthActions, Selectors as AuthSelectors } from 'state/auth'
import { Actions as UIActions, Selectors as UISelectors } from 'state/ui'

const mapStateToProps = state => ({
  currentUser: AuthSelectors.getCurrentUser(state),
  isOpen: UISelectors.getIsSidebarOpen(state),
})

const mapDispatchToProps = {
  onClose: UIActions.closeSidebar,
  onSignOut: AuthActions.signOut,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)
