import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import NextIcon from '@material-ui/icons/NavigateNext'
import PrevIcon from '@material-ui/icons/NavigateBefore'
import cx from 'classnames'

const styles = theme => ({
  button: {
    borderRadius: 0,
  },
  hidden: {
    visibility: 'hidden',
  },
})

const PaginationControls = ({
  onNext,
  onPrev,
  hasNextPage,
  hasPrevPage,
  page,
  pageCount,
  classes,
}) => (
  <div className="flex items-center justify-center">
    <IconButton
      onClick={onPrev}
      className={cx(classes.button, !hasPrevPage && classes.hidden)}>
      <PrevIcon />
    </IconButton>
    <div>
      {page} / {pageCount === 0 ? 1 : pageCount}
    </div>
    <IconButton
      onClick={onNext}
      className={cx(classes.button, !hasNextPage && classes.hidden)}>
      <NextIcon />
    </IconButton>
  </div>
)

export default withStyles(styles)(PaginationControls)
