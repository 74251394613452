import React from 'react'
import { connect } from 'react-redux'
import {
  Actions as ClientActions,
  Selectors as ClientSelectors,
} from 'state/clients'
import {
  Actions as OrderActions,
  Selectors as OrderSelectors,
} from 'state/orders'
import { Route } from 'react-router-dom'
import MeasurementsPage from 'pages/MeasurementsPage'
import EditMeasurementsPage from 'pages/EditMeasurementsPage'
import CreateShirtPage from 'pages/CreateShirtPage'
import ReviewOrderPage from 'pages/ReviewOrderPage'
import OrderPage from 'pages/OrderPage'

const mapStateToProps = (state, { match }) => ({
  client: ClientSelectors.getMember(state, match.params.id),
  order: OrderSelectors.getOrder(state, match.params.orderId),
})

const mapDispatchToProps = {
  loadClient: ClientActions.load,
  loadOrder: OrderActions.load,
  cancelOrder: OrderActions.cancel,
}

class OrderRoutes extends React.Component {
  componentDidMount() {
    this.props.loadClient(this.props.match.params.id)
    this.props.loadOrder(this.props.match.params.orderId)
  }
  render() {
    const { match, client, order, cancelOrder } = this.props
    if (!client || !order) return null

    const passProps = {
      client,
      order,
    }

    return (
      <React.Fragment>
        <Route
          exact
          path={match.url}
          render={props => <OrderPage {...props} {...passProps} />}
        />
        <Route
          path={`${match.url}/measurements`}
          exact
          render={props => (
            <MeasurementsPage
              {...props}
              {...passProps}
              cancelOrder={cancelOrder}
            />
          )}
        />
        <Route
          path={`${match.url}/measurements/edit`}
          exact
          render={props => (
            <EditMeasurementsPage
              {...props}
              {...passProps}
              cancelOrder={cancelOrder}
            />
          )}
        />
        <Route
          exact
          path={`${match.url}/create-shirt`}
          render={props => (
            <CreateShirtPage
              {...props}
              {...passProps}
              cancelOrder={cancelOrder}
            />
          )}
        />
        <Route
          exact
          path={`${match.url}/review`}
          render={props => (
            <ReviewOrderPage
              {...props}
              {...passProps}
              cancelOrder={cancelOrder}
            />
          )}
        />
      </React.Fragment>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderRoutes)
