import { combineReducers } from 'redux'
import { keyBy } from 'lodash'
import * as Constants from './constants'

const byId = (state = {}, action) => {
  switch (action.type) {
    case Constants.LOAD_SUCCESS:
      return {
        ...state,
        ...keyBy(action.payload.measurements, 'id'),
      }
    case Constants.CREATE_SUCCESS:
    case Constants.UPDATE_SUCCESS:
      return {
        ...state,
        [action.payload.measurement.id]: action.payload.measurement,
      }
    default:
      return state
  }
}

const isUpdating = (state = false, action) => {
  switch (action.type) {
    case Constants.UPDATE:
    case Constants.UPDATING:
      return true
    case Constants.UPDATE_SUCCESS:
    case Constants.UPDATE_FAILURE:
      return false
    default:
      return state
  }
}

const isLoading = (state = false, action) => {
  switch (action.type) {
    case Constants.LOAD:
      return true
    case Constants.LOAD_SUCCESS:
    case Constants.LOAD_FAILURE:
      return false
    default:
      return state
  }
}

const tab = (state = 'regular', action) => {
  switch (action.type) {
    case Constants.SET_TAB:
      return action.payload.tab
    default:
      return state
  }
}

export default combineReducers({
  byId,
  isUpdating,
  isLoading,
  tab,
})
