import * as Constants from './constants'
import * as Actions from './actions'
import { Actions as UIActions } from '../ui'
import { Actions as MeasurementActions } from '../measurements'
import xhr from 'xhr'
import * as Api from 'state/api'
import { updateMeasurements } from '../clients/sagas'
import { takeLatest, all, call, put, throttle } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { confirmSaga } from 'state/ui/sagas'
import parseLinkHeader from 'parse-link-header'

export function* loadOrder(orderId) {
  try {
    yield put(Actions.loadPending())

    const { data } = yield call(Api.loadOrder, orderId)
    yield put(Actions.loadSuccess({ order: data }))
    return data
  } catch (error) {
    yield put(Actions.loadFailure(error))
  }
}

export function* loadAll(clientId, status) {
  try {
    yield put(Actions.loadAllPending())
    const response = yield call(xhr, {
      method: 'get',
      url: '/orders',
      params: {
        client_id: clientId,
        status,
      },
    })

    const total = response.headers.total
    const perPage = response.headers['per-page']

    const pageCount = Math.ceil(total / perPage)
    const links = parseLinkHeader(response.headers.link) || {}

    yield put(
      Actions.loadAllSuccess({
        orders: response.data,
        pageCount,
        page: Number(response.headers.page),
        perPage,
        total,
        nextPage: links['next'],
        prevPage: links['prev'],
        lastPage: links['last'],
      }),
    )
    return response.data
  } catch (error) {
    yield put(Actions.loadAllFailure(error))
  }
}

export function* loadDashboard() {
  try {
    yield put(Actions.loadDashboardPending())
    const { data } = yield call(xhr, {
      method: 'get',
      url: `/orders/dashboard`,
    })

    yield put(Actions.loadDashboardSuccess({ orders: data }))
    return data
  } catch (error) {
    yield put(Actions.loadDashboardFailure(error))
  }
}

export function* create(clientId, params) {
  try {
    yield put(Actions.createPending())
    const body = {
      order: {
        client_id: clientId,
        ...params,
      },
    }
    const { data } = yield call(Api.createOrder, body)

    yield put(Actions.createSuccess({ order: data }))
    return data
  } catch (error) {
    yield put(Actions.createFailure(error))
  }
}

export function* update(id, params) {
  try {
    yield put(Actions.updatePending())
    const { data } = yield call(Api.updateOrder, id, params)
    yield put(Actions.updateSuccess(data))
    return data
  } catch (error) {
    yield put(Actions.updateFailure(error))
  }
}

export function* submitForReview(orderId) {
  try {
    const { data } = yield call(Api.submitOrderForReview, orderId)

    yield put(Actions.submitForReviewSuccess(data))
    return data
  } catch (error) {
    yield put(Actions.submitForReviewFailure(error))
    throw error
  }
}

export function* submitToManufacturer(orderId) {
  try {
    const { data } = yield call(Api.submitOrderToManufacturer, orderId)

    yield put(Actions.submitToManufacturerSuccess(data))
    return data
  } catch (error) {
    yield put(Actions.submitToManufacturerFailure(error))
  }
}

export function* deleteOrder(orderId) {
  try {
    yield put(Actions.removePending())
    yield call(Api.removeOrder, orderId)
    yield put(Actions.removeSuccess(orderId))
  } catch (error) {
    yield put(Actions.removeFailure(error))
  }
}

export function* duplicate(orderId) {
  try {
    yield put(Actions.duplicatePending())
    const { data } = yield call(Api.duplicateOrder, orderId)
    yield put(Actions.duplicateSuccess(data))
    return data
  } catch (error) {
    yield put(Actions.duplicateFailure(error))
  }
}

export function* handleLoad({ payload: { id } }) {
  const order = yield loadOrder(id)
  if (order.fitting_type) {
    yield put(MeasurementActions.setTab(order.fitting_type))
  }
}

export function* handleLoadAll({ payload: { clientId, status } }) {
  yield loadAll(clientId, status)
}

export function* handleLoadDashboard() {
  yield loadDashboard()
}

export function* handleUpdate({
  payload: { orderId, params, transitionTo, shouldWarn },
}) {
  let confirmed = true
  if (shouldWarn) {
    confirmed = yield call(confirmSaga, {
      title: 'Are you sure?',
      message: 'Not all shirt components have been selected',
    })
  }
  if (!confirmed) return
  yield update(orderId, params)
  yield put(UIActions.closeEditShirt())
  yield put(UIActions.closeEditNotes())
  if (transitionTo) {
    yield put(push(transitionTo))
  }
}

export function* handleCreate({ payload: { clientId, params, shouldWarn } }) {
  let confirmed = true
  if (shouldWarn) {
    confirmed = yield call(confirmSaga, {
      title: 'Are you sure?',
      message: 'Not all shirt components have been selected',
    })
  }
  if (!confirmed) return
  const order = yield create(clientId, params)
  yield put(push(`/clients/${clientId}/orders/${order.id}/measurements`))
}

export function* handleCreateGarment({ payload: { orderId, params } }) {
  const order = yield update(orderId, { order: { garment: params } })
  yield put(push(`/clients/${order.client_id}/orders/${order.id}/review`))
}

export function* handleSubmitOrdersForReview({ payload: { orders } }) {
  try {
    for (var i = 0; i < orders.length; i++) {
      yield submitForReview(orders[i].id)
    }
    yield put(push(`/`))
    yield put(UIActions.openOrderSubmitted())
  } catch (error) {
    console.error(error) // TODO handle this
  }
}

export function* handleSubmitOrderForReview({ payload: { orderId } }) {
  try {
    yield submitForReview(orderId)
    yield put(push(`/`))
    yield put(UIActions.openOrderSubmitted())
  } catch (error) {
    console.error(error) // TODO handle this
  }
}
export function* handleSubmitToManufacturer({ payload: { orderId } }) {
  yield submitToManufacturer(orderId)
  yield put(push('/'))
}

export function* updateMeasurementsAndOrder({
  payload: {
    clientId,
    orderId,
    measurementParams,
    orderParams,
    shouldWarn,
    transitionTo,
  },
}) {
  let confirmed = true
  if (shouldWarn) {
    confirmed = yield call(confirmSaga, {
      title: 'Are you sure?',
      message: 'Not all measurements have been entered.',
    })
  }
  if (!confirmed) return
  yield updateMeasurements(clientId, measurementParams)
  const formData = new FormData()
  Object.keys(orderParams).forEach(key => {
    if (!!orderParams[key]) {
      formData.append(`order[${key}]`, orderParams[key])
    }
  })
  yield update(orderId, formData)
  if (transitionTo) {
    yield put(push(transitionTo))
  }
  yield put(UIActions.closeEditMeasurements())
}

export function* search({ payload: { query } }) {
  try {
    const { data } = yield call(xhr, {
      method: 'get',
      url: '/orders/search',
      params: {
        q: query,
      },
    })

    yield put(Actions.searchSuccess({ query, orders: data }))
  } catch (error) {
    yield put(Actions.searchFailure(error))
  }
}

export function* handleRemove({ payload: { orderId } }) {
  const confirmed = yield call(confirmSaga, {
    title: 'Are you sure you want to remove this order?',
  })
  if (!confirmed) return
  yield deleteOrder(orderId)
}

export function* handleDuplicate({ payload: { orderId } }) {
  yield duplicate(orderId)
}

export function* handleAddToCart({ payload: { order, notes } }) {
  yield update(order.id, { order: { notes } })
  yield put(push(`/clients/${order.client_id}/orders/review`))
}

export function* handleCancel({ payload: { orderId } }) {
  const confirmed = yield call(confirmSaga, {
    title: 'Are you sure you want to cancel this order?',
  })
  if (!confirmed) return
  yield deleteOrder(orderId)
  yield put(push('/'))
}

export default function* Orders() {
  yield all([
    takeLatest(Constants.LOAD, handleLoad),
    takeLatest(Constants.LOAD_ALL, handleLoadAll),
    takeLatest(Constants.LOAD_DASHBOARD, handleLoadDashboard),
    takeLatest(Constants.CREATE, handleCreate),
    takeLatest(Constants.UPDATE, handleUpdate),
    takeLatest(
      Constants.UPDATE_MEASUREMENTS_AND_ORDER,
      updateMeasurementsAndOrder,
    ),
    takeLatest(Constants.CREATE_GARMENT, handleCreateGarment),
    takeLatest(Constants.ADD_TO_CART, handleAddToCart),
    takeLatest(Constants.SUBMIT_ORDERS_FOR_REVIEW, handleSubmitOrdersForReview),
    takeLatest(Constants.SUBMIT_FOR_REVIEW, handleSubmitOrderForReview),
    takeLatest(Constants.SUBMIT_TO_MANUFACTURER, handleSubmitToManufacturer),
    takeLatest(Constants.REMOVE, handleRemove),
    takeLatest(Constants.DUPLICATE, handleDuplicate),
    takeLatest(Constants.CANCEL, handleCancel),
    throttle(500, Constants.SEARCH, search),
  ])
}
