import { connect } from 'react-redux'
import {
  Actions as OrderActions,
  Selectors as OrderSelectors,
} from 'state/orders'

const mapStateToProps = state => ({
  orders: OrderSelectors.getSearch(state),
  isLoading: OrderSelectors.getIsLoading(state),
})

const mapDispatchToProps = {
  search: OrderActions.search,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)
