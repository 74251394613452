import React from 'react'
import { Field } from 'redux-form'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import Input from 'components/TextInput'
import Select from 'components/SelectInput'
import { required, email } from 'utils/form-validators'
import { normalizePhone } from 'utils/helpers'
import Avatar from 'react-avatar'
import states from 'utils/states'

const TextInput = ({ input, ...rest }) => {
  return (
    <FormControl margin="normal" fullWidth>
      <Input {...input} {...rest} />
    </FormControl>
  )
}
const SelectInput = ({ input, children, ...rest }) => {
  return (
    <FormControl margin="normal" fullWidth>
      <Select {...input} {...rest}>
        {children}
      </Select>
    </FormControl>
  )
}

class FileInput extends React.Component {
  state = {
    file: null,
  }

  onChange = e => {
    const {
      input: { onChange },
    } = this.props
    this.setState({ file: e.target.files[0] })
    onChange(e.target.files[0])
  }

  render() {
    const {
      input: { value },
    } = this.props
    const { input } = this.props
    const { file } = this.state
    const avatarProps = {
      className: 'avatar',
      value: 'Image Upload',
      size: 200,
      color: '#D8D8D8',
      fgColor: 'black',
    }
    return (
      <div>
        {file ? (
          <Avatar {...avatarProps} src={URL.createObjectURL(file)} />
        ) : (
          <Avatar {...avatarProps} src={value ? value : null} />
        )}
        <div>
          <input
            type="file"
            accept=".jpg, .png, .jpeg"
            onChange={this.onChange}
          />
        </div>
      </div>
    )
  }
}

const capitalize = value => value.replace(/\b\w/g, l => l.toUpperCase())

const Fields = () => (
  <Grid container>
    <Grid item xs={12} sm={4} md={3}>
      <Field label="Avatar" name="avatar" component={FileInput} />
    </Grid>
    <Grid item xs={12} sm={8} md={6}>
      <Field
        autoFocus
        component={TextInput}
        fullWidth
        label="First name"
        name="first_name"
        placeholder="Enter first name"
        required
        validate={[required]}
        normalize={capitalize}
      />
      <Field
        component={TextInput}
        fullWidth
        label="Last name"
        name="last_name"
        placeholder="Enter last name"
        required
        validate={[required]}
        normalize={capitalize}
      />
      <Field
        component={TextInput}
        fullWidth
        label="Phone Number"
        name="phone"
        placeholder="Enter phone number"
        normalize={normalizePhone} // TODO fix this to strip punctuation
      />
      <Field
        component={TextInput}
        fullWidth
        label="Email Address"
        name="email"
        placeholder="Enter email address"
        required
        validate={[required, email]}
      />
      <Field
        name="street_address"
        component={TextInput}
        label="Address Line 1"
        fullWidth
        placeholder="Enter address"
        normalize={capitalize}
      />
      <Field
        name="street_address_2"
        component={TextInput}
        label="Address Line 2"
        fullWidth
        placeholder="Apt, Suite, Room"
        normalize={capitalize}
      />
      <Field
        name="city"
        component={TextInput}
        label="City"
        fullWidth
        placeholder="Enter city"
        normalize={capitalize}
      />
      <Grid container justify="space-between" spacing={16}>
        <Grid item xs={6}>
          <Field name="state" component={SelectInput} label="State" fullWidth>
            <option disabled key="" value="">
              Select state
            </option>
            {states.map((state, index) => (
              <option key={index} value={state.value}>
                {state.name}
              </option>
            ))}
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="zipcode"
            component={TextInput}
            label="Zip code"
            fullWidth
            placeholder="Enter Zipcode"
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
)

export default Fields
