import React from 'react'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { withStyles } from '@material-ui/core/styles'
import { PrimaryButton, OutlinedButton } from 'components/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'

const styles = theme => ({
  root: {
    maxWidth: 500,
    padding: theme.spacing.unit * 3,
  },
  content: {
    flex: 0,
    marginBottom: theme.spacing.unit * 2,
  },
  title: {
    textAlign: 'center',
    fontSize: '1.25em',
    margin: 0,
    marginBottom: 12,
    lineHeight: 1.5,
  },
})

const Confirmation = ({
  open,
  title,
  message,
  onCancel,
  onConfirm,
  classes,
  width,
  primaryText = 'Yes',
  secondaryText = 'No',
  ...other
}) => (
  <Dialog
    disableBackdropClick
    disableEscapeKeyDown
    maxWidth="sm"
    fullScreen={!isWidthUp('sm', width)}
    classes={{ paper: classes.root }}
    open={open}
    {...other}>
    {title && (
      <DialogTitle disableTypography className="center">
        <h2 className={classes.title}>{title}</h2>
      </DialogTitle>
    )}
    <DialogContent className="center" classes={{ root: classes.content }}>
      {message}
    </DialogContent>
    <div>
      <PrimaryButton onClick={onConfirm} fullWidth style={{ marginBottom: 12 }}>
        {primaryText}
      </PrimaryButton>
      <OutlinedButton onClick={onCancel} fullWidth>
        {secondaryText}
      </OutlinedButton>
    </div>
  </Dialog>
)

export default withWidth()(withStyles(styles)(Confirmation))
