import { combineReducers } from 'redux'
import * as Constants from './constants'

const isValidatingToken = (state = true, action) => {
  switch (action.type) {
    case Constants.VALIDATE_TOKEN_SUCCESS:
    case Constants.VALIDATE_TOKEN_FAILURE:
      return false
    default:
      return state
  }
}

const currentUser = (state = null, action) => {
  switch (action.type) {
    case Constants.SIGN_IN_SUCCESS:
    case Constants.VALIDATE_TOKEN_SUCCESS:
      return action.payload.user
    case Constants.SIGN_OUT_SUCCESS:
      return null
    default:
      return state
  }
}

const signInError = (state = false, action) => {
  switch (action.type) {
    case Constants.SIGN_IN_FAILURE:
      return true
    case Constants.SIGN_IN_SUCCESS:
      return false
    default:
      return state
  }
}

export default combineReducers({
  currentUser,
  isValidatingToken,
  signInError,
})
