import { last } from 'lodash'

export const getIsUpdating = state => state.measurements.isUpdating
export const getById = state => state.measurements.byId
export const getMember = (state, id) => state.measurements.byId[id]
export const getCollection = (state, ids) =>
  ids.map(id => state.measurements.byId[id])

export const getForClient = (state, clientId) =>
  last(
    Object.values(state.measurements.byId).filter(
      m => m.client_id === Number(clientId),
    ),
  )

export const getCurrentTab = state => state.measurements.tab
