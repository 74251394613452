import { connect } from 'react-redux'
import {
  Actions as ClientActions,
  Selectors as ClientSelectors,
} from 'state/clients'

const mapStateToProps = state => ({
  clients: ClientSelectors.getSearch(state),
  isLoading: ClientSelectors.getIsLoading(state),
})

const mapDispatchToProps = {
  search: ClientActions.search,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)
