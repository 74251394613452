import React from 'react'
import { reduxForm } from 'redux-form'
import Container from 'components/Container'
import ClientFields from 'components/ClientForm'
import ActionSheet from 'components/common/ActionSheet'
import { Section } from 'components/common/SectionHeader'

const CustomerForm = ({ handleSubmit, onCancel }) => (
  <div>
    <Container>
      <Section>
        <ClientFields />
      </Section>
    </Container>
    <ActionSheet
      primaryText="Next"
      onPrimary={handleSubmit}
      onCancel={onCancel}
    />
  </div>
)

const ClientForm = reduxForm({
  form: 'customer',
})(CustomerForm)

class CreateClientPage extends React.Component {
  handleSubmit = values => {
    this.props.createClient(values)
  }

  handleCancel = () => {
    if (window.confirm('Are you sure?')) {
      this.props.history.push('/')
    }
  }

  render() {
    return (
      <div style={{ marginBottom: 160 }}>
        <Container>
          <h1>Create Client Profile</h1>
        </Container>
        <ClientForm onSubmit={this.handleSubmit} onCancel={this.handleCancel} />
      </div>
    )
  }
}

export default CreateClientPage
