import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Paper from '@material-ui/core/Paper'
import Input from 'components/TextInput'
import styles from './styles'

class SignInPage extends React.Component {
  state = {
    email: '',
    password: '',
  }

  componentDidMount() {
    if (this.props.isSignedIn) {
      this.props.history.push('/')
    } else {
      this.props.validateToken()
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isSignedIn && this.props.isSignedIn) {
      this.props.history.push('/')
    }
  }

  handleChange = attr => {
    return e => this.setState({ [attr]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.onSignIn(this.state)
  }

  render() {
    const { classes, error } = this.props
    const { email, password } = this.state

    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <img
            src="https://static1.squarespace.com/static/5959aa5f3e00bea5c5062d50/t/5afd03598a922d5621cd5be9/1542155862493"
            className={classes.logo}
            alt="ESQ"
          />
          <div className={classes.container}>
            <form className={classes.form}>
              <FormControl margin="normal" required fullWidth>
                <Input
                  label="Email Address"
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={this.handleChange('email')}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <Input
                  label="Password"
                  name="password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={this.handleChange('password')}
                />
              </FormControl>
              {error && (
                <div className={classes.error}>
                  <p>Incorrect username or password. Please try again.</p>
                </div>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={this.handleSubmit}
                className={classes.submit}>
                Log in
              </Button>
            </form>
          </div>
        </Paper>
      </main>
    )
  }
}
SignInPage.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SignInPage)
