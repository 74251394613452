export const baseFields = [
  'neck',
  'chest',
  'waist',
  'stomach',
  'hip',
  'front_length',
  'front_width',
  'back_length',
  'back_width',
  'shoulder',
  'bicep',
  'armhole',
  'forearm',
  'wrist_left',
  'wrist_right',
  'sleeve',
]

export const tapedFields = [
  'neck_taped',
  'chest_taped',
  'waist_taped',
  'stomach_taped',
  'hip_taped',
  'front_length_taped',
  'front_width_taped',
  'back_length_taped',
  'back_width_taped',
  'shoulder_taped',
  'bicep_taped',
  'armhole_taped',
  'forearm_taped',
  'wrist_right_taped',
  'wrist_left_taped',
  'sleeve',
]

export const finishedFields = [
  'neck_finished',
  'chest_finished',
  'waist_finished',
  'stomach_finished',
  'hip_finished',
  'front_length_finished',
  'front_width_finished',
  'back_length_finished',
  'back_width_finished',
  'shoulder_finished',
  'bicep_finished',
  'armhole_finished',
  'forearm_finished',
  'wrist_left_finished',
  'wrist_right_finished',
  'sleeve_finished',
]

export const allowanceMap = {
  neck_taped: {
    slim: 2,
    regular: 2,
    classic: 3,
  },
  shoulder_taped: {
    slim: 0,
    regular: 0,
    classic: 1,
  },
  chest_taped: {
    slim: 7,
    regular: 8,
    classic: 9,
  },
  waist_taped: {
    slim: 7,
    regular: 8,
    classic: 9,
  },
  stomach_taped: {
    slim: 6,
    regular: 8,
    classic: 10,
  },
  hip_taped: {
    slim: 7,
    regular: 8,
    classic: 8,
  },
  front_length_taped: {
    slim: 1,
    regular: 2,
    classic: 2.5,
  },
  front_width_taped: {
    slim: 1.5,
    regular: 2,
    classic: 3,
  },
  back_length_taped: {
    slim: 1,
    regular: 2,
    classic: 2.5,
  },
  back_width_taped: {
    slim: 3,
    regular: 4,
    classic: 5,
  },
  bicep_taped: {
    slim: 5,
    regular: 6,
    classic: 8,
  },
  armhole_taped: {
    slim: 5,
    regular: 6,
    classic: 8,
  },
  forearm_taped: {
    slim: 5,
    regular: 6,
    classic: 7,
  },
  wrist_left_taped: {
    slim: 5,
    regular: 5,
    classic: 6,
  },
  wrist_right_taped: {
    slim: 5,
    regular: 5,
    classic: 6,
  },
  sleeve_taped: {
    slim: 1.5,
    regular: 2,
    classic: 2,
  },
}
