import React from 'react'
import Container from 'components/Container'
import OrderList from 'components/OrderList'
import SectionHeader, { Section } from 'components/common/SectionHeader'
import PaginationControls from 'components/PaginationControls'
import queryString from 'query-string'

class OrderListPage extends React.Component {
  componentDidMount() {
    this.props.loadOrders({ status: this.getFilter() })
  }

  getFilter = () => {
    const params = queryString.parse(this.props.location.search)
    return params.status
  }

  handleNextPage = () => {
    this.props.loadAll({ page: this.props.nextPage })
  }

  handlePrevPage = () => {
    this.props.loadAll({ page: this.props.prevPage })
  }

  getHeader = () => {
    const filter = this.getFilter()
    switch (filter) {
      case 'open':
        return 'Open Orders'
      case 'submitted':
        return 'Submitted Orders'
      case 'review':
        return 'Orders Ready for Review'
      default:
        return 'All Orders'
    }
  }
  render() {
    const { orders, page, pageCount, hasNextPage, hasPrevPage } = this.props
    const header = this.getHeader()
    return (
      <Container>
        <Section>
          <SectionHeader title={header} border={false} />
          <OrderList orders={orders} />
        </Section>
        <Section>
          <PaginationControls
            onNext={this.handleNextPage}
            onPrev={this.handlePrevPage}
            hasNextPage={hasNextPage}
            hasPrevPage={hasPrevPage}
            page={page}
            pageCount={pageCount}
          />
        </Section>
      </Container>
    )
  }
}

export default OrderListPage
