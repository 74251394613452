import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import FormControl from '@material-ui/core/FormControl'
import TextInput from 'components/TextInput'
import IconButton from '@material-ui/core/IconButton'
import SubtractIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import InputAdornment from '@material-ui/core/InputAdornment'

const STEP = 0.5

const styles = theme => ({
  button: {
    color: 'black',
    borderRadius: 0,
    border: '2px solid black',
    padding: 12,
    touchAction: 'manipulation',
  },
  buttonLeft: {
    borderRight: 'none',
  },
})

class Input extends React.Component {
  handleIncrement = () => {
    const { value, onChange } = this.props
    onChange(value ? Number(value) + STEP : STEP)
  }

  handleDecrement = () => {
    const { value, onChange } = this.props
    onChange(value ? Number(value) - STEP : 0)
  }

  render() {
    const { input, classes, ...rest } = this.props
    const buttons = (
      <InputAdornment className="ml1">
        <IconButton
          onClick={this.handleDecrement}
          tabIndex="-1"
          className={cx(classes.button, classes.buttonLeft)}>
          <SubtractIcon />
        </IconButton>
        <IconButton
          onClick={this.handleIncrement}
          tabIndex="-1"
          className={classes.button}>
          <AddIcon />
        </IconButton>
      </InputAdornment>
    )
    return (
      <FormControl fullWidth margin="dense">
        <TextInput
          {...input}
          {...rest}
          inputProps={{ step: '0.5', inputMode: 'numeric', type: 'tel' }}
          endAdornment={buttons}
        />
      </FormControl>
    )
  }
}

export default withStyles(styles)(Input)
