import React from 'react'
import Container from 'components/Container'
import MeasurementsForm from 'components/MeasurementsForm'
import ActionSheet from 'components/common/ActionSheet'
import { pick, omit } from 'lodash'

class MeasurementsPage extends React.Component {
  handleSubmit = () => {
    const { client, order, tab, formValues: values, initialValues } = this.props

    const measurementKeys = Object.keys(values).filter(
      field => !field.match(/image/),
    )
    const shouldWarn = measurementKeys.some(field => !values[field])
    const dirty = Object.keys(values).filter(
      key => values[key] !== initialValues[key],
    )
    const params = pick(values, dirty)

    const imageKeys = [
      'front_image',
      'side_image',
      'back_image',
      'additional_image',
    ]

    const orderParams = {
      fitting_type: tab,
      ...pick(params, imageKeys),
    }

    const measurementParams = omit(params, imageKeys)

    this.props.updateMeasurementsAndOrder({
      clientId: client.id,
      orderId: order.id,
      measurementParams,
      orderParams,
      shouldWarn,
      transitionTo: `/clients/${client.id}/orders/${order.id}/create-shirt`,
    })
  }

  handleSave = () => {
    const { client, order, tab, formValues: values, initialValues } = this.props
    const measurementKeys = Object.keys(values).filter(
      field => !field.match(/image/),
    )

    const shouldWarn = measurementKeys.some(field => !values[field])

    const dirty = Object.keys(values).filter(
      key => values[key] !== initialValues[key],
    )

    const params = pick(values, dirty)

    const imageKeys = [
      'front_image',
      'side_image',
      'back_image',
      'additional_image',
    ]

    const orderParams = {
      fitting_type: tab,
      ...pick(params, imageKeys),
    }

    const measurementParams = omit(params, imageKeys)

    this.props.updateMeasurementsAndOrder({
      clientId: client.id,
      orderId: order.id,
      measurementParams,
      orderParams,
      shouldWarn,
      transitionTo: `/clients/${client.id}/orders/${order.id}`,
    })
  }

  handleCancel = orderId => {
    this.props.cancelOrder(orderId)
  }

  render() {
    const { client, order } = this.props

    return (
      <div style={{ marginBottom: 160 }}>
        <Container>
          <h1>Measurements</h1>
          <MeasurementsForm measurements={client.measurements} order={order} />
        </Container>
        <ActionSheet
          primaryText="Next"
          onPrimary={this.handleSubmit}
          secondaryText="Save Changes"
          onSecondary={this.handleSave}
          onCancel={() => this.handleCancel(order.id)}
        />
      </div>
    )
  }
}

export default MeasurementsPage
