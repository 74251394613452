import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { baseFields as measurementFields } from 'components/MeasurementsForm/data'
import { capitalize } from 'lodash'

const styles = theme => ({
  label: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: '2.3px',
    paddingBottom: theme.spacing.unit,
  },
})

const formatHeight = measurements => {
  const { height_feet: feet, height_inches: inches } = measurements
  if (!feet && !inches) return 'N/A'

  return `${feet || 0}' ${inches || 0}"`
}

const formatWeight = measurements => {
  const { weight_pounds: weight } = measurements
  if (!weight) return 'N/A'

  return `${weight} lbs`
}

const MeasurementSummary = ({
  measurements,
  showFinished = false,
  fitType,
  classes,
}) => {
  return (
    <Grid container>
      <Grid item xs={6} sm={4} md={3}>
        <div className="mb2">
          <div className={classes.label}>Height</div>
          {formatHeight(measurements)}
        </div>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <div className="mb2">
          <div className={classes.label}>Weight</div>
          {formatWeight(measurements)}
        </div>
      </Grid>
      {fitType && (
        <Grid item xs={6} sm={4} md={3}>
          <div className="mb2">
            <div className={classes.label}>Shirt Fit</div>
            {capitalize(fitType)}
          </div>
        </Grid>
      )}
      {measurementFields.map(field => {
        const taped = measurements[`${field}_taped`]
        const tapedDisplay = taped ? `${taped} cm` : '--'
        const finished = measurements[`${field}_finished`]
        const finishedDisplay = taped ? `${finished} cm` : '--'
        return (
          <Grid item xs={6} sm={4} md={3} key={field}>
            <div className="mb2">
              <div className={classes.label}>{field.split('_').join(' ')}</div>
              {showFinished
                ? `${tapedDisplay} / ${finishedDisplay}`
                : `${tapedDisplay}`}
            </div>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default withStyles(styles)(MeasurementSummary)
