import states from 'utils/states'
import { keyBy } from 'lodash'

export const getInitials = name =>
  name
    .split(' ')
    .map(n => n[0].toUpperCase())
    .join('')

export const getFullName = (user = {}) => `${user.first_name} ${user.last_name}`

export const normalizePhone = (value, previousValue) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (!previousValue || value.length > previousValue.length) {
    if (onlyNums.length === 3) {
      return onlyNums + '-'
    }
    if (onlyNums.length === 6) {
      return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3) + '-'
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 6) {
    return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3)
  }
  return (
    onlyNums.slice(0, 3) +
    '-' +
    onlyNums.slice(3, 6) +
    '-' +
    onlyNums.slice(6, 10)
  )
}

export const getStateAbbrev = state => {
  if (!state) return ''
  const stateMap = keyBy(states, 'name')
  return stateMap[state] ? stateMap[state].abbrev : ''
}
