import React from 'react'
import { connect } from 'react-redux'
import { Actions as UIActions, Selectors as UISelectors } from 'state/ui'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { withStyles } from '@material-ui/core/styles'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'

const styles = theme => ({
  root: {
    maxWidth: 500,
    padding: theme.spacing.unit * 3,
  },
  content: {
    flex: 0,
    marginBottom: theme.spacing.unit * 2,
  },
  title: {
    textAlign: 'center',
    fontSize: '1.25em',
    margin: 0,
    marginBottom: 12,
    lineHeight: 1.5,
  },
})

const OrderSubmittedDialog = ({ open, onClose, classes, width, ...other }) => (
  <Dialog
    maxWidth="md"
    fullScreen={!isWidthUp('sm', width)}
    classes={{ paper: classes.root }}
    open={open}
    onClose={onClose}
    {...other}>
    <DialogTitle disableTypography className="center">
      <h1 className={classes.title}>Your order has been submitted</h1>
    </DialogTitle>
    <DialogContent className="center" classes={{ root: classes.content }}>
      Thank you for your order!
    </DialogContent>
  </Dialog>
)

const mapStateToProps = state => ({
  open: UISelectors.getIsOrderSubmittedOpen(state),
})

const mapDispatchToProps = {
  onClose: UIActions.closeOrderSubmitted,
}

export default withWidth()(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(OrderSubmittedDialog),
  ),
)
