import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import CircularProgress from '@material-ui/core/CircularProgress'
import connect from './connect'

const styles = theme => ({
  dialog: {
    background: 'transparent',
    boxShadow: 'none',
  },
})

const Loading = ({ classes, open }) => (
  <Dialog
    fullScreen={false}
    open={open}
    disableBackdropClick
    disableEscapeKeyDown
    classes={{ paper: classes.dialog }}
    aria-labelledby="responsive-dialog-title">
    <CircularProgress />
  </Dialog>
)

export default connect(withMobileDialog()(withStyles(styles)(Loading)))
