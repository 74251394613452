import { combineReducers } from 'redux'
import { keyBy } from 'lodash'
import * as Constants from './constants'

const byId = (state = {}, action) => {
  switch (action.type) {
    case Constants.LOAD_SUCCESS:
    case Constants.CREATE_SUCCESS:
    case Constants.UPDATE_SUCCESS:
    case Constants.UPDATE_MEASUREMENTS_SUCCESS:
      return {
        ...state,
        [action.payload.client.id]: action.payload.client,
      }
    case Constants.SEARCH_SUCCESS:
    case Constants.LOAD_ALL_SUCCESS:
      return {
        ...state,
        ...keyBy(action.payload.clients, 'id'),
      }
    default:
      return state
  }
}

const byQuery = (state = {}, action) => {
  switch (action.type) {
    case Constants.SEARCH_SUCCESS:
      return {
        ...state,
        [JSON.stringify(action.payload.query)]: action.payload.clients,
      }
    default:
      return state
  }
}

const isLoading = (state = false, action) => {
  switch (action.type) {
    case Constants.LOAD_PENDING:
    case Constants.LOAD_ALL_PENDING:
    case Constants.SEARCH:
      return true
    case Constants.LOAD_SUCCESS:
    case Constants.LOAD_FAILURE:
    case Constants.SEARCH_SUCCESS:
    case Constants.SEARCH_FAILURE:
      return false
    default:
      return state
  }
}

const isCreating = (state = false, action) => {
  switch (action.type) {
    case Constants.CREATE_PENDING:
      return true
    case Constants.CREATE_SUCCESS:
    case Constants.CREATE_FAILURE:
      return false
    default:
      return state
  }
}

const isUpdating = (state = false, action) => {
  switch (action.type) {
    case Constants.UPDATE_PENDING:
      return true
    case Constants.UPDATE_SUCCESS:
    case Constants.UPDATE_FAILURE:
    case Constants.UPDATE_MEASUREMENTS_SUCCESS:
    case Constants.UPDATE_MEASUREMENTS_FAILURE:
      return false
    default:
      return state
  }
}

const paginated = (state = {}, action) => {
  switch (action.type) {
    case Constants.LOAD_ALL_SUCCESS:
      return action.payload
    default:
      return state
  }
}

const search = (state = [], action) => {
  switch (action.type) {
    case Constants.SEARCH_SUCCESS:
      return action.payload.clients
    default:
      return state
  }
}
export default combineReducers({
  byId,
  byQuery,
  isLoading,
  isCreating,
  isUpdating,
  paginated,
  search,
})
