import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Container from 'components/Container'
import Avatar from 'react-avatar'
import { OutlinedButton } from 'components/Button'
import SectionHeader, { Section } from 'components/common/SectionHeader'
import MeasurementSummary from 'components/MeasurementSummary'
import OrderList from 'components/OrderList'
import { getStateAbbrev } from 'utils/helpers'

const styles = theme => ({
  label: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: '2.3px',
    paddingBottom: theme.spacing.unit,
  },
})

class ClientShowPage extends React.Component {
  componentDidMount() {
    this.props.loadOrders({ clientId: this.props.client.id })
  }

  handleCreateOrder = () => {
    this.props.createOrder({ clientId: this.props.client.id })
  }

  render() {
    const { client, orders } = this.props
    if (!client) return null
    return (
      <Container>
        <Section>
          <SectionHeader title="Profile">
            <Link to={`/clients/${client.id}/edit`}>Edit</Link>
          </SectionHeader>
          <Grid container>
            <Grid item xs={12} sm={3} className="center">
              <Avatar
                src={client.avatar}
                name={client.full_name}
                className="avatar"
                size={200}
                color="#D8D8D8"
                fgColor="black"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="ml3">
                <h3 style={{ textTransform: 'capitalize' }}>
                  {client.first_name} {client.last_name}
                </h3>
                <ul className="list-reset ml3" style={{ listStyle: 'disc' }}>
                  {client.email && <li className="mb1">{client.email}</li>}
                  {client.phone && <li className="mb1">{client.phone}</li>}
                  {client.street_address && (
                    <li className="mb1">
                      <div>{client.street_address}</div>
                      <div>{client.street_address2}</div>
                      <div>
                        {client.city &&
                          client.state &&
                          `${client.city}, ${getStateAbbrev(client.state)}`}
                        {client.zipcode}
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={3}>
              <OutlinedButton onClick={this.handleCreateOrder}>
                Create Order
              </OutlinedButton>
            </Grid>
          </Grid>
        </Section>
        <div className="flex justify-between items-center mt2">
          <h1>Measurements</h1>
        </div>
        <div className="borderTop mb3" />
        <Section>
          <MeasurementSummary measurements={client.measurements} />
        </Section>
        <Section>
          <SectionHeader title="Orders" border={false} />
          <OrderList orders={orders} showStatus />
        </Section>
      </Container>
    )
  }
}

export default withStyles(styles)(ClientShowPage)
