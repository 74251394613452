import React from 'react'
import Avatar from 'react-avatar'
import Dropzone from 'react-dropzone'

const baseStyle = {
  height: 200,
  width: 200,
  marginBottom: 16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const activeStyle = {
  borderStyle: 'solid',
  borderColor: '#6c6',
  backgroundColor: '#eee',
}
const rejectStyle = {
  borderStyle: 'solid',
  borderColor: '#c66',
  backgroundColor: '#eee',
}

class ImageUploader extends React.Component {
  state = {
    file: null,
  }

  onChange = files => {
    const {
      input: { onChange },
    } = this.props
    this.setState({ file: files[0] })
    onChange(files[0])
  }

  render() {
    const {
      input: { value },
    } = this.props

    const { input, label } = this.props
    const { file } = this.state
    const avatarProps = {
      className: 'avatar',
      value: label || 'Image Upload',
      size: 200,
      color: '#D8D8D8',
      fgColor: 'black',
      textSizeRatio: 1.75,
      style: {
        fontSize: '1em',
      },
    }

    return (
      <Dropzone accept="image/*" onDrop={this.onChange}>
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragAccept,
          isDragReject,
          acceptedFiles,
          rejectedFiles,
        }) => {
          let styles = { ...baseStyle }
          styles = isDragActive ? { ...styles, ...activeStyle } : styles
          styles = isDragReject ? { ...styles, ...rejectStyle } : styles

          return (
            <div {...getRootProps()} style={styles}>
              <input {...getInputProps()} />
              {file ? (
                <Avatar {...avatarProps} src={URL.createObjectURL(file)} />
              ) : value ? (
                <Avatar {...avatarProps} src={value} />
              ) : (
                <React.Fragment>
                  <div>{label}</div>
                  {isDragReject && <div>Unsupported file type...</div>}
                </React.Fragment>
              )}
            </div>
          )
        }}
      </Dropzone>
    )
  }
}

export default ImageUploader
