import * as Constants from './constants'
import * as Actions from './actions'
import { Actions as OrderActions } from '../orders'
import xhr from 'xhr'
import { takeLatest, all, call, put, throttle } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import parseLinkHeader from 'parse-link-header'

export function* load(id) {
  try {
    yield put(Actions.loadPending())
    const { data } = yield call(xhr, {
      method: 'get',
      url: `/clients/${id}`,
    })

    yield put(Actions.loadSuccess({ client: data }))
    return data
  } catch (error) {
    yield put(Actions.loadFailure(error))
  }
}

export function* loadAll(query) {
  try {
    yield put(Actions.loadAllPending())
    const response = yield call(xhr, {
      method: 'get',
      url: '/clients',
      params: query,
    })

    const total = response.headers.total
    const perPage = response.headers['per-page']

    const pageCount = Math.ceil(total / perPage)
    const links = parseLinkHeader(response.headers.link)

    yield put(
      Actions.loadAllSuccess({
        clients: response.data,
        pageCount,
        page: Number(response.headers.page),
        perPage,
        total,
        nextPage: links['next'],
        prevPage: links['prev'],
        lastPage: links['last'],
      }),
    )
    return response.data
  } catch (error) {
    yield put(Actions.loadAllFailure(error))
  }
}

export function* create(params) {
  try {
    yield put(Actions.createPending())
    const { data } = yield call(xhr, {
      method: 'post',
      url: '/clients',
      data: params,
    })

    yield put(Actions.createSuccess({ client: data }))
    return data
  } catch (error) {
    yield put(Actions.createFailure(error))
  }
}

export function* update(clientId, params) {
  try {
    yield put(Actions.updatePending())
    const { data } = yield call(xhr, {
      method: 'put',
      url: `/clients/${clientId}`,
      data: params,
    })

    yield put(Actions.updateSuccess({ client: data }))
    return data
  } catch (error) {
    yield put(Actions.updateFailure(error))
  }
}
export function* updateMeasurements(clientId, params) {
  try {
    yield put(Actions.updatePending())
    const { data } = yield call(xhr, {
      method: 'put',
      url: `/clients/${clientId}/measurements`,
      data: {
        measurement: params,
      },
    })
    yield put(Actions.updateMeasurementsSuccess({ client: data }))
    return data
  } catch (error) {
    yield put(Actions.updateMeasurementsFailure(error))
  }
}

export function* handleLoad({ payload: { id } }) {
  yield load(id)
}

export function* handleLoadAll({ payload: { query } }) {
  yield loadAll(query)
}

export function* handleCreate({ payload: { params } }) {
  const client = yield create(params)
  yield put(OrderActions.create({ clientId: client.id }))
}

export function* handleUpdate({ payload: { clientId, params, transitionTo } }) {
  try {
    const formData = new FormData()
    Object.keys(params).forEach(key => {
      formData.append(`client[${key}]`, params[key])
    })
    yield update(clientId, formData)
    if (transitionTo) {
      yield put(push(transitionTo))
    }
  } catch (error) {
    yield put(Actions.updateFailure(error))
  }
}

export function* search({ payload: { query } }) {
  try {
    const { data } = yield call(xhr, {
      method: 'get',
      url: '/clients/search',
      params: {
        q: query,
      },
    })

    yield put(Actions.searchSuccess({ query, clients: data }))
  } catch (error) {
    yield put(Actions.searchFailure(error))
  }
}

export default function* Clients() {
  yield all([
    takeLatest(Constants.LOAD, handleLoad),
    takeLatest(Constants.LOAD_ALL, handleLoadAll),
    takeLatest(Constants.CREATE, handleCreate),
    takeLatest(Constants.UPDATE, handleUpdate),
    throttle(500, Constants.SEARCH, search),
  ])
}
