import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

const drawerWidth = 300

const styles = theme => ({
  root: {
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    height: 'calc(100vh - 64px)',
    overflow: 'scroll',
  },
  drawerPaper: {
    width: drawerWidth,
    position: 'absolute',
    background: '#F7F7F7',
    paddingLeft: '3em',
    zIndex: 0,
    paddingBottom: '4em',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    paddingBottom: 140,
    overflow: 'scroll',
    height: 'calc(100vh - 64px)',
  },
  selected: {
    borderLeft: `2px solid ${theme.palette.secondary.main}`,
  },
})

function PermanentDrawerLeft(props) {
  const {
    classes,
    options,
    selected,
    onChange,
    garment,
    children,
    keyedOptions,
  } = props

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left">
        <List>
          <h1>Build a Shirt</h1>
          {options.map((option, index) => {
            const value = garment[option.identifier]
            const optionValues = keyedOptions[option.identifier].values
            let secondary = ''
            if (option.value_type === 'selection') {
              const selectedOption = optionValues.find(
                v => v.identifier === value,
              )
              secondary = selectedOption && selectedOption.display
            } else {
              secondary = garment[option.identifier]
            }

            return (
              <ListItem
                button
                key={option.id}
                divider
                selected={option.identifier === selected}
                onClick={() => onChange(index)}
                classes={{
                  selected: classes.selected,
                }}>
                <ListItemText primary={option.display} secondary={secondary} />
              </ListItem>
            )
          })}
        </List>
      </Drawer>
      <div className={classes.content}>{children}</div>
    </div>
  )
}

PermanentDrawerLeft.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(PermanentDrawerLeft)
