import React from 'react'

const OrderStatus = ({ status, className }) => {
  const color =
    status === 'Submitted'
      ? '#658355'
      : status === 'Ready for Review'
        ? '#724B4E'
        : '#9A9A9A'
  return (
    <p
      style={{
        borderRadius: 5,
        border: '2px solid black',
        borderColor: color,
        padding: '2px 8px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: 14,
        color,
      }}
      className={className}>
      {status}
    </p>
  )
}

export default OrderStatus
