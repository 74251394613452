import React from 'react'
import ShirtForm from 'components/ShirtForm'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import ActionSheet from 'components/common/ActionSheet'

const required = [
  'fabric_number',
  'placket',
  'pocket',
  'collar',
  'cuff',
  'fabric_vendor',
  'darts',
  'yoke',
  'bottoms',
]

class EditShirt extends React.Component {
  handleSubmit = () => {
    const { formValues, order } = this.props
    const shouldWarn = required.some(key => !formValues[key])

    this.props.updateOrder({
      orderId: order.id,
      params: {
        garment: formValues,
      },
      shouldWarn,
    })
  }

  handleCancel = () => {
    this.props.onClose()
  }

  render() {
    const { order } = this.props
    if (!order) return null
    return (
      <React.Fragment>
        <div>
          <ShirtForm order={order} formValues={this.props.formValues} />
          <ActionSheet
            primaryText="Save Changes"
            onPrimary={this.handleSubmit}
            onCancel={this.handleCancel}
          />
        </div>
      </React.Fragment>
    )
  }
}

function Transition(props) {
  return <Slide direction="up" {...props} />
}

const Modal = ({ isOpen, onClose, ...rest }) => (
  <Dialog
    open={isOpen}
    TransitionComponent={Transition}
    keepMounted
    fullScreen
    onClose={onClose}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description">
    <EditShirt {...rest} onClose={onClose} />
  </Dialog>
)

export default Modal
