import { connect } from 'react-redux'
import { Actions as OrderActions } from 'state/orders'
import { getFormValues, isValid } from 'redux-form'

const mapStateToProps = (state, { match }) => ({
  formValues: getFormValues('buildGarment')(state),
  valid: isValid('buildGarment')(state),
})

const mapDispatchToProps = {
  createGarment: OrderActions.createGarment,
  updateOrder: OrderActions.update,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)
