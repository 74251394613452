import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import SectionHeader from 'components/common/SectionHeader'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import TextField from 'components/TextInput'
import { PrimaryButton, OutlinedButton } from 'components/Button'

class EditNotes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      notes: props.order.notes || '',
    }
  }

  handleSave = () => {
    this.props.updateOrder({
      orderId: this.props.order.id,
      params: {
        notes: this.state.notes,
      },
    })
  }

  handleCancel = () => {
    this.props.onClose()
  }

  handleNoteChange = e => this.setState({ notes: e.target.value })

  render() {
    const { order } = this.props
    if (!order) return null
    return (
      <div>
        <SectionHeader title="Notes" subheader border={false} />
        <TextField
          autoFocus
          rows={8}
          multiline
          placeholder="Enter any notes for the order here"
          fullWidth
          value={this.state.notes}
          onChange={this.handleNoteChange}
        />
        <div style={{ width: '75%', margin: '0 auto' }} className="pt2">
          <PrimaryButton
            onClick={this.handleSave}
            size="large"
            fullWidth
            style={{ marginBottom: 12 }}>
            Save
          </PrimaryButton>
          <OutlinedButton
            onClick={this.handleCancel}
            size="large"
            fullWidth
            className="mb3">
            Cancel
          </OutlinedButton>
        </div>
      </div>
    )
  }
}

function Transition(props) {
  return <Slide direction="up" {...props} />
}

const styles = theme => ({
  root: {
    width: 800,
    padding: theme.spacing.unit * 3,
    paddingTop: 0,
  },
  content: {
    flex: 0,
    marginBottom: theme.spacing.unit * 2,
  },
  title: {
    textAlign: 'center',
    fontSize: '1.25em',
    margin: 0,
    marginBottom: 12,
    lineHeight: 1.5,
  },
})

const Modal = ({ classes, isOpen, onClose, ...rest }) => (
  <Dialog
    open={isOpen}
    TransitionComponent={Transition}
    classes={{ paper: classes.root }}
    keepMounted
    maxWidth="sm"
    onClose={onClose}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description">
    <DialogContent className="center" classes={{ root: classes.content }}>
      {isOpen && <EditNotes {...rest} onClose={onClose} />}
    </DialogContent>
  </Dialog>
)

export default withStyles(styles)(Modal)
