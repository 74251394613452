import { connect } from 'react-redux'
import {
  Actions as MeasurementActions,
  Selectors as MeasurementSelectors,
} from 'state/measurements'
import { Actions as OrderActions } from 'state/orders'
import { getFormValues, getFormInitialValues } from 'redux-form'

const mapStateToProps = (state, props) => ({
  tab: MeasurementSelectors.getCurrentTab(state),
  initialValues: getFormInitialValues('measurements')(state),
  formValues: getFormValues('measurements')(state),
})

const mapDispatchToProps = {
  updateMeasurementsAndOrder: OrderActions.updateMeasurementsAndOrder,
  onTabChange: MeasurementActions.requestSetTab,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)
