import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Select from '@material-ui/core/Select'
import Icon from '@material-ui/icons/KeyboardArrowDown'
import TextInput from 'components/TextInput'

const styles = theme => ({
  input: {
    width: 'calc(100% - 46px)',
    '&:disabled': {
      color: 'red',
    },
  },
  icon: {
    right: 6,
  },
})

const SelectInput = ({ classes, label, children, ...rest }) => {
  const { value } = rest
  return (
    <React.Fragment>
      <Select
        native
        input={
          <TextInput
            label={label}
            style={{ color: !value ? 'rgb(117, 117, 117)' : 'currentColor' }}
          />
        }
        classes={{
          select: classes.input,
          icon: classes.icon,
        }}
        IconComponent={Icon}
        {...rest}>
        {children}
      </Select>
    </React.Fragment>
  )
}

export default withStyles(styles)(SelectInput)
