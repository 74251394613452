import { connect } from 'react-redux'
import { Actions as OrderActions } from 'state/orders'
import { Selectors as MeasurementSelectors } from 'state/measurements'
import { Selectors as UISelectors, Actions as UIActions } from 'state/ui'
import { getFormValues, getFormInitialValues } from 'redux-form'

const mapStateToProps = (state, props) => ({
  isOpen: UISelectors.getIsEditMeasurementsOpen(state),
  initialValues: getFormInitialValues('measurements')(state),
  formValues: getFormValues('measurements')(state),
  tab: MeasurementSelectors.getCurrentTab(state),
})

const mapDispatchToProps = {
  updateMeasurementsAndOrder: OrderActions.updateMeasurementsAndOrder,
  onClose: UIActions.closeEditMeasurements,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)
