import * as Constants from './constants'
import * as Actions from './actions'
import xhr from 'xhr'
import { takeLatest, all, call, put } from 'redux-saga/effects'

export function* validateToken() {
  try {
    const { data } = yield call(xhr, {
      method: 'get',
      url: '/auth/validate_token',
    })
    yield put(Actions.validateTokenSuccess({ user: data.data }))
  } catch (error) {
    yield put(Actions.validateTokenFailure(error))
  }
}

export function* signIn({ payload: { email, password } }) {
  try {
    const response = yield call(xhr, {
      method: 'post',
      url: '/auth/sign_in',
      params: {
        email,
        password,
      },
    })
    saveHeaders(response.headers)
    yield put(Actions.signInSuccess({ user: response.data.data }))
  } catch (error) {
    yield put(Actions.signInFailure(error))
  }
}

function saveHeaders(headers) {
  localStorage.setItem('access-token', headers['access-token'])
  localStorage.setItem('client', headers['client'])
  localStorage.setItem('expiry', headers['expiry'])
  localStorage.setItem('token-type', headers['token-type'])
  localStorage.setItem('uid', headers['uid'])
}

export function* signOut() {
  try {
    yield call(xhr, {
      method: 'delete',
      url: '/auth/sign_out',
    })
    localStorage.removeItem('access-token')
    localStorage.removeItem('client')
    localStorage.removeItem('expiry')
    localStorage.removeItem('token-type')
    localStorage.removeItem('uid')
    yield put(Actions.signOutSuccess())
  } catch (error) {
    yield put(Actions.signOutFailure(error))
  }
}

export default function* Auth() {
  yield all([
    takeLatest(Constants.SIGN_IN, signIn),
    takeLatest(Constants.SIGN_OUT, signOut),
    takeLatest(Constants.VALIDATE_TOKEN, validateToken),
  ])
}
