import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form'
import auth from './auth'
import clients from './clients'
import measurements from './measurements'
import garmentTypes from './garmentTypes'
import orders from './orders'
import ui from './ui'

export default history =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    auth,
    clients,
    measurements,
    garmentTypes,
    orders,
    ui,
  })
