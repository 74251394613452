import React from 'react'
import Container from 'components/Container'
import MeasurementsForm from 'components/MeasurementsForm'
import ActionSheet from 'components/common/ActionSheet'
import { pick, omit } from 'lodash'

class EditMeasurementsPage extends React.Component {
  handleSubmit = () => {
    const { client, order, formValues: values, initialValues } = this.props

    const measurementKeys = Object.keys(values).filter(
      field => !field.match(/image/),
    )
    const shouldWarn = measurementKeys.some(field => !values[field])
    const dirty = Object.keys(values).filter(
      key => values[key] !== initialValues[key],
    )
    const params = pick(values, dirty)

    const imageKeys = [
      'front_image',
      'side_image',
      'back_image',
      'additional_image',
    ]

    const orderParams = {
      fitting_type: this.props.tab,
      ...pick(params, imageKeys),
    }

    const measurementParams = omit(params, imageKeys)

    this.props.updateMeasurementsAndOrder({
      clientId: client.id,
      orderId: order.id,
      measurementParams,
      orderParams,
      shouldWarn,
      transitionTo: `/clients/${client.id}/orders/${order.id}`,
    })
  }

  handleCancel = orderId => {
    const { client, order } = this.props
    this.props.history.push(`/clients/${client.id}/orders/${order.id}`)
  }

  render() {
    const { client, order } = this.props
    return (
      <Container>
        <h1>Edit Measurements</h1>
        <MeasurementsForm measurements={client.measurements} order={order} />
        <ActionSheet
          primaryText="Update"
          onPrimary={this.handleSubmit}
          onCancel={() => this.handleCancel(order.id)}
        />
      </Container>
    )
  }
}

export default EditMeasurementsPage
