import * as Constants from './constants'
import * as Actions from './actions'
import xhr from 'xhr'
import { take, takeLatest, all, call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { confirmSaga } from 'state/ui/sagas'

export function* load({ payload: { clientId } }) {
  try {
    const { data } = yield call(xhr, {
      method: 'get',
      url: `/clients/${clientId}/measurements`,
    })

    yield put(Actions.loadSuccess({ measurements: data }))
  } catch (error) {
    yield put(Actions.loadFailure(error))
  }
}

export function* update({
  payload: { clientId, params, measurementId, shouldWarn },
}) {
  let confirmed = true
  if (shouldWarn) {
    confirmed = yield call(confirmSaga, {
      title: 'Are you sure?',
      message: 'Not all measurements have been entered.',
    })
  }
  if (!confirmed) return
  try {
    const { data } = yield call(xhr, {
      method: 'put',
      url: `/clients/${clientId}/measurements`,
      data: {
        measurement: params,
      },
    })

    yield put(Actions.updateSuccess({ measurement: data }))
  } catch (error) {
    yield put(Actions.updateFailure(error))
  }
}

export function* create({ payload: { clientId, params, shouldWarn } }) {
  let confirmed = true
  if (shouldWarn) {
    confirmed = yield call(confirmSaga, {
      title: 'Are you sure?',
      message: 'Not all measurements have been entered.',
    })
  }
  if (!confirmed) return
  try {
    const { data } = yield call(xhr, {
      method: 'post',
      url: `/clients/${clientId}/measurements`,
      data: {
        measurement: params,
      },
    })

    yield put(Actions.createSuccess({ measurement: data }))
    yield put(push(`/clients/${clientId}/select-garment`))
  } catch (error) {
    yield put(Actions.createFailure(error))
  }
}

export function* setFittingType() {
  while (true) {
    const {
      payload: { tab },
    } = yield take(Constants.REQUEST_SET_TAB)
    const confirmed = yield call(confirmSaga, {
      title: 'Are you sure you want to change the shirt type?',
      message: "Any changes you've made will not be saved.",
    })
    if (!confirmed) {
      continue
    }
    yield put(Actions.setTab(tab))
  }
}

export default function* Measurements() {
  yield all([
    takeLatest(Constants.LOAD, load),
    takeLatest(Constants.CREATE, create),
    takeLatest(Constants.UPDATE, update),
    setFittingType(),
  ])
}
