import { connect } from 'react-redux'
import { Selectors as AuthSelectors, Actions as AuthActions } from 'state/auth'
import { Actions as UIActions } from 'state/ui'

const mapStateToProps = state => ({
  isSignedIn: AuthSelectors.getIsSignedIn(state),
  currentUser: AuthSelectors.getCurrentUser(state),
})

const mapDispatchToProps = {
  signOut: AuthActions.signOut,
  openSidebar: UIActions.openSidebar,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)
