import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Avatar from 'react-avatar'
import moment from 'moment'
import OrderStatus from 'components/common/OrderStatus'

const styles = theme => ({
  overflow: {
    width: '80%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})

const OrderList = ({ orders, classes, showStatus }) => (
  <List className="borderTop">
    {orders.length ? (
      orders.map(order => (
        <ListItem
          button
          component={Link}
          to={`/clients/${order.client_id}/orders/${order.id}`}
          key={order.id}
          divider>
          <ListItemAvatar>
            <Avatar
              className="avatar"
              name={order.client.full_name}
              src={order.client.avatar}
              size={100}
              color="#D8D8D8"
              fgColor="black"
            />
          </ListItemAvatar>
          <ListItemText
            primary={order.client.full_name}
            disableTypography
            secondary={
              <div>
                <div className={classes.overflow}>{order.client.email}</div>
                <div>#{order.order_number}</div>
                <div>{moment(order.created_at).format('MM/DD/YY')}</div>
              </div>
            }
          />
          <div className="flex items-center">
            {showStatus && (
              <OrderStatus status={order.status} className="mr2" />
            )}
            <ChevronRight />
          </div>
        </ListItem>
      ))
    ) : (
      <ListItem>No Orders</ListItem>
    )}
  </List>
)

export default withStyles(styles)(OrderList)
