import * as Constants from './constants'

export const load = clientId => ({
  type: Constants.LOAD,
  payload: {
    clientId,
  },
})

export const loadSuccess = ({ measurements }) => ({
  type: Constants.LOAD_SUCCESS,
  payload: {
    measurements,
  },
})

export const loadFailure = error => ({
  type: Constants.LOAD_FAILURE,
  payload: {
    error,
  },
})

export const create = ({ clientId, params, shouldWarn }) => ({
  type: Constants.CREATE,
  payload: {
    clientId,
    params,
    shouldWarn,
  },
})

export const createSuccess = ({ measurement }) => ({
  type: Constants.CREATE_SUCCESS,
  payload: {
    measurement,
  },
})

export const createFailure = error => ({
  type: Constants.CREATE_FAILURE,
  payload: {
    error,
  },
})

export const update = ({ clientId, measurementId, params, shouldWarn }) => ({
  type: Constants.UPDATE,
  payload: {
    clientId,
    measurementId,
    params,
    shouldWarn,
  },
})

export const updateSuccess = ({ measurement }) => ({
  type: Constants.UPDATE_SUCCESS,
  payload: {
    measurement,
  },
})

export const updateFailure = error => ({
  type: Constants.UPDATE_FAILURE,
  payload: {
    error,
  },
})

export const requestSetTab = tab => ({
  type: Constants.REQUEST_SET_TAB,
  payload: {
    tab,
  },
})

export const setTab = tab => ({
  type: Constants.SET_TAB,
  payload: {
    tab,
  },
})

export const updating = () => ({
  type: Constants.UPDATING,
})
