import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Downshift from 'downshift'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import styles from './styles'
import { withRouter } from 'react-router-dom'
import TextInput from 'components/TextInput'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from 'react-avatar'
import OrderStatus from 'components/common/OrderStatus'
import moment from 'moment'

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps

  return (
    <div className={classes.search}>
      <TextInput
        inputProps={{
          ...InputProps,
        }}
        {...other}
        fullWidth
      />
    </div>
  )
}

function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem,
}) {
  const isHighlighted = highlightedIndex === index

  return (
    <ListItem
      {...itemProps}
      selected={isHighlighted}
      button
      component={Link}
      to={`/clients/${suggestion.client_id}/orders/${suggestion.id}`}
      key={suggestion.id}
      divider>
      <ListItemAvatar>
        <Avatar
          className="avatar"
          name={suggestion.client.full_name}
          src={suggestion.client.avatar}
          size={100}
          color="#D8D8D8"
          fgColor="black"
        />
      </ListItemAvatar>
      <ListItemText
        primary={suggestion.client.full_name}
        disableTypography
        secondary={
          <div>
            <div>{suggestion.client.email}</div>
            <div>#{suggestion.order_number}</div>
            <div>{moment(suggestion.created_at).format('MM/DD/YY')}</div>
          </div>
        }
      />
      <OrderStatus status={suggestion.status} />
    </ListItem>
  )
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
}

class OrderSearch extends React.Component {
  state = {
    input: '',
  }

  handleInputChange = event => {
    const input = event.target.value
    this.setState({ input }, () => {
      this.props.search(input)
    })
  }

  handleSelect = item => this.props.history.push(`/orders/${item.id}`)

  render() {
    const { classes, orders, isLoading } = this.props
    const { input } = this.state

    return (
      <div>
        <Downshift
          id="downshift-simple"
          inputValue={input}
          itemToString={item => (item ? item.first_name : '')}
          onSelect={this.handleSelect}>
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            highlightedIndex,
            inputValue,
            isOpen,
            selectedItem,
          }) => (
            <div className={classes.container}>
              {renderInput({
                classes,
                InputProps: getInputProps({
                  onChange: this.handleInputChange,
                  placeholder: 'Search for order...',
                }),
              })}
              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper className={classes.paper} square>
                    {orders.length ? (
                      orders.map((suggestion, index) =>
                        renderSuggestion({
                          suggestion,
                          index,
                          itemProps: getItemProps({
                            item: suggestion,
                          }),
                          highlightedIndex,
                          selectedItem,
                        }),
                      )
                    ) : (
                      <MenuItem component="div">
                        {isLoading ? 'Loading...' : 'No results'}
                      </MenuItem>
                    )}
                  </Paper>
                ) : null}
              </div>
            </div>
          )}
        </Downshift>
      </div>
    )
  }
}

OrderSearch.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(withRouter(OrderSearch))
