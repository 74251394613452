import { connect } from 'react-redux'
import { Actions as OrderActions } from 'state/orders'
import { Selectors as UISelectors, Actions as UIActions } from 'state/ui'

const mapStateToProps = (state, { match }) => ({
  confirmationOpen: UISelectors.getIsOrderSubmittedOpen(state),
})

const mapDispatchToProps = {
  submitOrder: OrderActions.submit,
  closeConfirmation: UIActions.closeOrderSubmitted,
  addToCart: OrderActions.addToCart,
  openEditMeasurements: UIActions.openEditMeasurements,
  openEditShirt: UIActions.openEditShirt,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)
