export const LOAD = 'Orders/LOAD'
export const LOAD_PENDING = 'Orders/LOAD_PENDING'
export const LOAD_SUCCESS = 'Orders/LOAD_SUCCESS'
export const LOAD_FAILURE = 'Orders/LOAD_FAILURE'

export const LOAD_ALL = 'Orders/LOAD_ALL'
export const LOAD_ALL_PENDING = 'Orders/LOAD_ALL_PENDING'
export const LOAD_ALL_SUCCESS = 'Orders/LOAD_ALL_SUCCESS'
export const LOAD_ALL_FAILURE = 'Orders/LOAD_ALL_FAILURE'

export const LOAD_DASHBOARD = 'Orders/LOAD_DASHBOARD'
export const LOAD_DASHBOARD_PENDING = 'Orders/LOAD_DASHBOARD_PENDING'
export const LOAD_DASHBOARD_SUCCESS = 'Orders/LOAD_DASHBOARD_SUCCESS'
export const LOAD_DASHBOARD_FAILURE = 'Orders/LOAD_DASHBOARD_FAILURE'

export const CREATE = 'Orders/CREATE'
export const CREATE_PENDING = 'Orders/CREATE_PENDING'
export const CREATE_SUCCESS = 'Orders/CREATE_SUCCESS'
export const CREATE_FAILURE = 'Orders/CREATE_FAILURE'

export const UPDATE_MEASUREMENTS_AND_ORDER =
  'Orders/UPDATE_MEASUREMENTS_AND_ORDER'

export const UPDATE = 'Orders/UPDATE'
export const UPDATE_PENDING = 'Orders/UPDATE_PENDING'
export const UPDATE_SUCCESS = 'Orders/UPDATE_SUCCESS'
export const UPDATE_FAILURE = 'Orders/UPDATE_FAILURE'

export const CREATE_GARMENT = 'Orders/CREATE_GARMENT'
export const CREATE_GARMENT_SUCCESS = 'Orders/CREATE_GARMENT_SUCCESS'
export const CREATE_GARMENT_FAILURE = 'Orders/CREATE_GARMENT_FAILURE'

export const SUBMIT_ORDERS_FOR_REVIEW = 'Orders/SUBMIT_ORDERS_FOR_REVIEW'

export const SUBMIT_FOR_REVIEW = 'Orders/SUBMIT_FOR_REVIEW'
export const SUBMIT_FOR_REVIEW_PENDING = 'Orders/SUBMIT_FOR_REVIEW_PENDING'
export const SUBMIT_FOR_REVIEW_SUCCESS = 'Orders/SUBMIT_FOR_REVIEW_SUCCESS'
export const SUBMIT_FOR_REVIEW_FAILURE = 'Orders/SUBMIT_FOR_REVIEW_FAILURE'

export const SUBMIT_TO_MANUFACTURER = 'Orders/SUBMIT_TO_MANUFACTURER'
export const SUBMIT_TO_MANUFACTURER_PENDING =
  'Orders/SUBMIT_TO_MANUFACTURER_PENDING'
export const SUBMIT_TO_MANUFACTURER_SUCCESS =
  'Orders/SUBMIT_TO_MANUFACTURER_SUCCESS'
export const SUBMIT_TO_MANUFACTURER_FAILURE =
  'Orders/SUBMIT_TO_MANUFACTURER_FAILURE'

export const NEW_CLIENT_ORDER_FLOW = 'Orders/NEW_CLIENT_ORDER_FLOW'

export const ORDER_IN_PROGRESS_LOADED = 'Orders/ORDER_IN_PROGRESS_LOADED'

export const ADD_TO_CART = 'Orders/ADD_TO_CART'

export const REMOVE = 'Orders/REMOVE'
export const REMOVE_PENDING = 'Orders/REMOVE_PENDING'
export const REMOVE_SUCCESS = 'Orders/REMOVE_SUCCESS'
export const REMOVE_FAILURE = 'Orders/REMOVE_FAILURE'

export const DUPLICATE = 'Orders/DUPLICATE'
export const DUPLICATE_PENDING = 'Orders/DUPLICATE_PENDING'
export const DUPLICATE_SUCCESS = 'Orders/DUPLICATE_SUCCESS'
export const DUPLICATE_FAILURE = 'Orders/DUPLICATE_FAILURE'

export const CANCEL = 'Orders/CANCEL'

export const SEARCH = 'Orders/SEARCH'
export const SEARCH_SUCCESS = 'Orders/SEARCH_SUCCESS'
export const SEARCH_FAILURE = 'Orders/SEARCH_FAILURE'
