import { createStore, applyMiddleware } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createHistory from 'history/createBrowserHistory'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'

import createRootReducer from 'state/reducers'
import sagas from 'state/sagas'

export const history = createHistory()

const sagaMiddleware = createSagaMiddleware()

const initialState = {}
const enhancers = []
const middleware = [routerMiddleware(history), sagaMiddleware, thunk]

const composedEnhancers = composeWithDevTools(
  applyMiddleware(...middleware),
  ...enhancers,
)

const store = createStore(
  createRootReducer(history),
  initialState,
  composedEnhancers,
)

sagaMiddleware.run(sagas)

export default store
