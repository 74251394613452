export const LOAD = 'Clients/LOAD'
export const LOAD_PENDING = 'Clients/LOAD_PENDING'
export const LOAD_SUCCESS = 'Clients/LOAD_SUCCESS'
export const LOAD_FAILURE = 'Clients/LOAD_FAILURE'

export const LOAD_ALL = 'Clients/LOAD_ALL'
export const LOAD_ALL_PENDING = 'Clients/LOAD_ALL_PENDING'
export const LOAD_ALL_SUCCESS = 'Clients/LOAD_ALL_SUCCESS'
export const LOAD_ALL_FAILURE = 'Clients/LOAD_ALL_FAILURE'

export const CREATE = 'Clients/CREATE'
export const CREATE_PENDING = 'Clients/CREATE_PENDING'
export const CREATE_SUCCESS = 'Clients/CREATE_SUCCESS'
export const CREATE_FAILURE = 'Clients/CREATE_FAILURE'

export const UPDATE = 'Clients/UPDATE'
export const UPDATE_PENDING = 'Clients/UPDATE_PENDING'
export const UPDATE_SUCCESS = 'Clients/UPDATE_SUCCESS'
export const UPDATE_FAILURE = 'Clients/UPDATE_FAILURE'

export const SEARCH = 'Clients/SEARCH'
export const SEARCH_SUCCESS = 'Clients/SEARCH_SUCCESS'
export const SEARCH_FAILURE = 'Clients/SEARCH_FAILURE'

export const UPDATE_MEASUREMENTS = 'Clients/UPDATE_MEASUREMENTS'
export const UPDATE_MEASUREMENTS_SUCCESS = 'Clients/UPDATE_MEASUREMENTS_SUCCESS'
export const UPDATE_MEASUREMENTS_FAILURE = 'Clients/UPDATE_MEASUREMENTS_FAILURE'
