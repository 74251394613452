import React from 'react'
import Container from 'components/Container'
import Grid from '@material-ui/core/Grid'
import Input from 'components/TextInput'
import Avatar from 'react-avatar'
import SectionHeader, { Section } from 'components/common/SectionHeader'
import ActionLink from 'components/common/ActionLink'
import MeasurementSummary from 'components/MeasurementSummary'
import EditMeasurementsModal from 'components/EditMeasurementsModal'
import EditShirtModal from 'components/EditShirtModal'
import ActionSheet from 'components/common/ActionSheet'
import ImageOption from 'components/common/ImageOption'

const OrderImage = ({ label, src }) =>
  src ? (
    <Avatar
      className="avatar"
      size={175}
      color="#D8D8D8"
      fgColor="black"
      textSizeRatio={1.75}
      style={{ fontSize: '1em', marginBottom: 8 }}
      value={label}
      src={src}
    />
  ) : (
    <div
      style={{
        height: 200,
        width: 200,
        marginBottom: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
      }}>
      {label}
    </div>
  )

const OrderImages = ({ order }) => (
  <Grid container spacing={24}>
    <Grid item xs={6} sm={4} md={3}>
      <OrderImage label="Front Image" src={order.front_image} />
    </Grid>
    <Grid item xs={6} sm={4} md={3}>
      <OrderImage label="Side Image" src={order.side_image} />
    </Grid>
    <Grid item xs={6} sm={4} md={3}>
      <OrderImage label="Back Image" src={order.back_image} />
    </Grid>
    <Grid item xs={6} sm={4} md={3}>
      <OrderImage label="Additional Image" src={order.additional_image} />
    </Grid>
  </Grid>
)

const GarmentOptions = ({ garment }) => {
  return (
    <Grid container>
      {garment.map((option, index) => (
        <Grid item xs={6} sm={4} md={3} key={index}>
          <h3 className="mb1">{option.option_display}</h3>
          <ImageOption display={option.value_display} imageUrl={option.image} />
        </Grid>
      ))}
    </Grid>
  )
}

class ReviewOrderPage extends React.Component {
  state = {
    notes: '',
  }

  componentDidMount() {
    this.setState({ notes: this.props.order.notes })
  }

  handleSubmit = () =>
    this.props.addToCart({ order: this.props.order, notes: this.state.notes })

  handleNoteChange = e => this.setState({ notes: e.target.value })

  handleEditShirt = order => {
    this.props.openEditShirt()
  }

  render() {
    const { client, order } = this.props
    // TODO - handle this page if order is already submitted

    return (
      <div style={{ marginBottom: 160 }}>
        <EditMeasurementsModal order={order} client={client} />
        <EditShirtModal order={order} />
        <Container>
          <Section>
            <SectionHeader
              title={`Review Shirt - #${order.order_number}`}
              border={false}
            />
          </Section>
          <Section>
            <SectionHeader title="Measurement & Fit" subheader>
              <ActionLink onClick={this.props.openEditMeasurements}>
                Edit
              </ActionLink>
            </SectionHeader>
            <MeasurementSummary
              measurements={client.measurements}
              showFinished
              fitType={order.fitting_type}
            />
          </Section>
          <Section>
            <OrderImages order={order} />
          </Section>
          <Section>
            <SectionHeader title="Shirt Design" subheader>
              <ActionLink onClick={this.handleEditShirt}>Edit</ActionLink>
            </SectionHeader>
            {order.garment && (
              <GarmentOptions garment={order.garment_summary} />
            )}
          </Section>
          <Section>
            <SectionHeader title="Notes" subheader />
            <Input
              rows={8}
              multiline
              placeholder="Enter any notes for the order here"
              fullWidth
              value={this.state.notes}
              onChange={this.handleNoteChange}
            />
          </Section>
        </Container>
        <ActionSheet
          primaryText="Add to Cart"
          onPrimary={this.handleSubmit}
          secondaryText="Save Changes"
          onSecondary={() => console.log('save')}
          onCancel={() => this.props.cancelOrder(order.id)}
        />
      </div>
    )
  }
}

export default ReviewOrderPage
