import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'

const styles = () => ({
  link: {
    textTransform: 'uppercase',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  padded: {
    marginLeft: 16,
  },
})

const ActionLink = ({ onClick, padded, classes, children }) => (
  <span
    onClick={onClick}
    className={cx(classes.link, padded && classes.padded)}>
    {children}
  </span>
)

export default withStyles(styles)(ActionLink)
