export default theme => ({
  bootstrapRoot: {
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
  },
  bootstrapInput: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: 16,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: ['"PT Serif"', '-apple-system'].join(','),
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
  bootstrapFormLabel: {
    fontSize: 18,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: '2.3px',
  },
  error: {
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  errorMessage: {
    color: theme.palette.secondary.main,
    fontSize: '0.9rem',
    fontStyle: 'italic',
    marginTop: 8,
    height: 14,
  },
})
