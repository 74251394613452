import { connect } from 'react-redux'
import { Actions as OrderActions } from 'state/orders'
import { Selectors as UISelectors, Actions as UIActions } from 'state/ui'
import {
  Actions as GarmentTypeActions,
  Selectors as GarmentTypeSelectors,
} from 'state/garmentTypes'
import { getFormValues, getFormInitialValues } from 'redux-form'

const mapStateToProps = (state, props) => ({
  isOpen: UISelectors.getIsEditShirtOpen(state),
  initialValues: getFormInitialValues('buildGarment')(state),
  garmentOptions: GarmentTypeSelectors.getShirtOptions(state),
  formValues: getFormValues('buildGarment')(state),
})

const mapDispatchToProps = {
  updateOrder: OrderActions.update,
  loadGarmentTypes: GarmentTypeActions.load,
  onClose: UIActions.closeEditShirt,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)
