import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import Container from 'components/Container'
import MeasurementsForm from 'components/MeasurementsForm'
import ActionSheet from 'components/common/ActionSheet'
import { pick, omit } from 'lodash'

class EditMeasurements extends React.Component {
  handleSubmit = () => {
    const { client, order, formValues: values, initialValues } = this.props

    const measurementKeys = Object.keys(values).filter(
      field => !field.match(/image/),
    )
    const shouldWarn = measurementKeys.some(field => !values[field])
    const dirty = Object.keys(values).filter(
      key => values[key] !== initialValues[key],
    )
    const params = pick(values, dirty)

    const imageKeys = [
      'front_image',
      'side_image',
      'back_image',
      'additional_image',
    ]

    const orderParams = {
      fitting_type: this.props.tab,
      ...pick(params, imageKeys),
    }

    const measurementParams = omit(params, imageKeys)

    this.props.updateMeasurementsAndOrder({
      clientId: client.id,
      orderId: order.id,
      measurementParams,
      orderParams,
      shouldWarn,
    })
  }

  handleCancel = () => {
    this.props.onClose()
  }

  render() {
    const { client, order } = this.props
    return (
      <React.Fragment>
        <Container style={{ marginBottom: 160 }}>
          <h1>Edit Measurements</h1>
          <MeasurementsForm measurements={client.measurements} order={order} />
        </Container>
        <ActionSheet
          primaryText="Save Changes"
          onPrimary={this.handleSubmit}
          onCancel={this.handleCancel}
        />
      </React.Fragment>
    )
  }
}

function Transition(props) {
  return <Slide direction="up" {...props} />
}

const Modal = ({ isOpen, onClose, ...rest }) => (
  <Dialog
    open={isOpen}
    TransitionComponent={Transition}
    keepMounted
    fullScreen
    onClose={onClose}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description">
    <EditMeasurements {...rest} onClose={onClose} />
  </Dialog>
)

export default Modal
