import { connect } from 'react-redux'
import { Actions as OrderActions } from 'state/orders'
import { Selectors as AuthSelectors } from 'state/auth'
import { Actions as UIActions } from 'state/ui'

const mapStateToProps = (state, props) => ({
  isAdmin: AuthSelectors.getIsAdmin(state),
})

const mapDispatchToProps = {
  submitToManufacturer: OrderActions.submitToManufacturer,
  submitForReview: OrderActions.submitForReview,
  cancelOrder: OrderActions.cancel,
  openEditMeasurements: UIActions.openEditMeasurements,
  openEditShirt: UIActions.openEditShirt,
  openEditNotes: UIActions.openEditNotes,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)
