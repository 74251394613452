import * as Constants from './constants'

export const load = id => ({
  type: Constants.LOAD,
  payload: {
    id,
  },
})

export const loadPending = () => ({
  type: Constants.LOAD_PENDING,
})

export const loadSuccess = ({ order }) => ({
  type: Constants.LOAD_SUCCESS,
  payload: {
    order,
  },
})

export const loadFailure = error => ({
  type: Constants.LOAD_FAILURE,
  payload: {
    error,
  },
})

export const loadAll = ({ clientId, status }) => ({
  type: Constants.LOAD_ALL,
  payload: {
    clientId,
    status,
  },
})

export const loadAllPending = () => ({
  type: Constants.LOAD_ALL_PENDING,
})

export const loadAllSuccess = ({
  orders,
  pageCount,
  page,
  perPage,
  total,
  nextPage,
  prevPage,
  lastPage,
}) => ({
  type: Constants.LOAD_ALL_SUCCESS,
  payload: {
    orders,
    pageCount,
    page,
    perPage,
    total,
    nextPage,
    prevPage,
    lastPage,
  },
})

export const loadAllFailure = error => ({
  type: Constants.LOAD_ALL_FAILURE,
  payload: {
    error,
  },
})

export const loadDashboard = () => ({
  type: Constants.LOAD_DASHBOARD,
})

export const loadDashboardPending = () => ({
  type: Constants.LOAD_DASHBOARD_PENDING,
})

export const loadDashboardSuccess = ({ orders }) => ({
  type: Constants.LOAD_DASHBOARD_SUCCESS,
  payload: {
    orders,
  },
})

export const loadDashboardFailure = error => ({
  type: Constants.LOAD_DASHBOARD_FAILURE,
  payload: {
    error,
  },
})

export const create = ({ clientId, ...params }) => ({
  type: Constants.CREATE,
  payload: {
    clientId,
    params,
  },
})

export const createPending = () => ({
  type: Constants.CREATE_PENDING,
})

export const createSuccess = ({ order }) => ({
  type: Constants.CREATE_SUCCESS,
  payload: {
    order,
  },
})

export const createFailure = error => ({
  type: Constants.CREATE_FAILURE,
  payload: {
    error,
  },
})

export const update = ({ orderId, params, transitionTo, shouldWarn }) => ({
  type: Constants.UPDATE,
  payload: {
    orderId,
    params,
    transitionTo,
    shouldWarn,
  },
})

export const updatePending = () => ({
  type: Constants.UPDATE_PENDING,
})

export const updateSuccess = order => ({
  type: Constants.UPDATE_SUCCESS,
  payload: {
    order,
  },
})

export const updateFailure = error => ({
  type: Constants.UPDATE_FAILURE,
  payload: {
    error,
  },
})

export const createGarment = ({ orderId, params, shouldWarn }) => ({
  type: Constants.CREATE_GARMENT,
  payload: {
    orderId,
    params,
    shouldWarn,
  },
})

export const createGarmentSuccess = order => ({
  type: Constants.CREATE_GARMENT_SUCCESS,
  payload: {
    order,
  },
})

export const createGarmentFailure = error => ({
  type: Constants.CREATE_GARMENT_FAILURE,
  payload: {
    error,
  },
})

export const submitOrdersForReview = orders => ({
  type: Constants.SUBMIT_ORDERS_FOR_REVIEW,
  payload: {
    orders,
  },
})

export const submitForReview = ({ orderId }) => ({
  type: Constants.SUBMIT_FOR_REVIEW,
  payload: {
    orderId,
  },
})

export const submitForReviewPending = () => ({
  type: Constants.SUBMIT_FOR_REVIEW_PENDING,
})

export const submitForReviewSuccess = order => ({
  type: Constants.SUBMIT_FOR_REVIEW_SUCCESS,
  payload: {
    order,
  },
})

export const submitForReviewFailure = error => ({
  type: Constants.SUBMIT_FOR_REVIEW_FAILURE,
  payload: {
    error,
  },
})

export const addToCart = ({ order, notes }) => ({
  type: Constants.ADD_TO_CART,
  payload: {
    order,
    notes,
  },
})

export const updateMeasurementsAndOrder = ({
  clientId,
  orderId,
  measurementParams,
  orderParams,
  shouldWarn,
  transitionTo,
}) => ({
  type: Constants.UPDATE_MEASUREMENTS_AND_ORDER,
  payload: {
    clientId,
    orderId,
    measurementParams,
    orderParams,
    shouldWarn,
    transitionTo,
  },
})

export const remove = orderId => ({
  type: Constants.REMOVE,
  payload: {
    orderId,
  },
})

export const removePending = () => ({
  type: Constants.REMOVE_PENDING,
})

export const removeSuccess = orderId => ({
  type: Constants.REMOVE_SUCCESS,
  payload: {
    orderId,
  },
})

export const removeFailure = error => ({
  type: Constants.REMOVE_FAILURE,
  payload: {
    error,
  },
})

export const duplicate = orderId => ({
  type: Constants.DUPLICATE,
  payload: {
    orderId,
  },
})

export const duplicatePending = () => ({
  type: Constants.DUPLICATE_PENDING,
})

export const duplicateSuccess = order => ({
  type: Constants.DUPLICATE_SUCCESS,
  payload: {
    order,
  },
})

export const duplicateFailure = error => ({
  type: Constants.DUPLICATE_FAILURE,
  payload: {
    error,
  },
})

export const cancel = orderId => ({
  type: Constants.CANCEL,
  payload: {
    orderId,
  },
})

export const submitToManufacturer = ({ orderId }) => ({
  type: Constants.SUBMIT_TO_MANUFACTURER,
  payload: {
    orderId,
  },
})

export const submitToManufacturerPending = () => ({
  type: Constants.SUBMIT_TO_MANUFACTURER_PENDING,
})

export const submitToManufacturerSuccess = order => ({
  type: Constants.SUBMIT_TO_MANUFACTURER_SUCCESS,
  payload: {
    order,
  },
})

export const submitToManufacturerFailure = error => ({
  type: Constants.SUBMIT_TO_MANUFACTURER_FAILURE,
  payload: {
    error,
  },
})

export const search = query => ({
  type: Constants.SEARCH,
  payload: {
    query,
  },
})

export const searchSuccess = ({ query, orders }) => ({
  type: Constants.SEARCH_SUCCESS,
  payload: {
    query,
    orders,
  },
})

export const searchFailure = error => ({
  type: Constants.SEARCH_FAILURE,
  payload: {
    error,
  },
})
