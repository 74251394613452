import { connect } from 'react-redux'
import { Selectors as AuthSelectors, Actions as AuthActions } from 'state/auth'

const mapStateToProps = state => ({
  isSignedIn: AuthSelectors.getIsSignedIn(state),
  error: AuthSelectors.getAuthError(state),
})

const mapDispatchToProps = {
  onSignIn: AuthActions.signIn,
  validateToken: AuthActions.validateToken,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)
