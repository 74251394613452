import { combineReducers } from 'redux'
import { keyBy, omit } from 'lodash'
import * as Constants from './constants'

const byId = (state = {}, action) => {
  switch (action.type) {
    case Constants.LOAD_ALL_SUCCESS:
    case Constants.LOAD_DASHBOARD_SUCCESS:
    case Constants.SEARCH_SUCCESS:
      return {
        ...state,
        ...keyBy(action.payload.orders, 'id'),
      }
    case Constants.CREATE_SUCCESS:
    case Constants.UPDATE_SUCCESS:
    case Constants.LOAD_SUCCESS:
    case Constants.SUBMIT_FOR_REVIEW_SUCCESS:
    case Constants.SUBMIT_TO_MANUFACTURER_SUCCESS:
    case Constants.DUPLICATE_SUCCESS:
      return {
        ...state,
        [action.payload.order.id]: action.payload.order,
      }
    case Constants.REMOVE_SUCCESS:
      return omit(state, action.payload.orderId)
    default:
      return state
  }
}

const search = (state = [], action) => {
  switch (action.type) {
    case Constants.SEARCH_SUCCESS:
      return action.payload.orders
    default:
      return state
  }
}

const paginated = (state = {}, action) => {
  switch (action.type) {
    case Constants.LOAD_ALL_SUCCESS:
      return action.payload
    default:
      return state
  }
}

const isLoading = (state = false, action) => {
  switch (action.type) {
    case Constants.LOAD_PENDING:
    case Constants.LOAD_ALL_PENDING:
    case Constants.LOAD_DASHBOARD_PENDING:
      return true
    case Constants.LOAD_SUCCESS:
    case Constants.LOAD_FAILURE:
    case Constants.LOAD_ALL_SUCCESS:
    case Constants.LOAD_ALL_FAILURE:
    case Constants.LOAD_DASHBOARD_SUCCESS:
    case Constants.LOAD_DASHBOARD_FAILURE:
      return false
    default:
      return state
  }
}

const isUpdating = (state = false, action) => {
  switch (action.type) {
    case Constants.UPDATE_PENDING:
      return true
    case Constants.UPDATE_SUCCESS:
    case Constants.UPDATE_FAILURE:
      return false
    default:
      return state
  }
}

const isCreating = (state = false, action) => {
  switch (action.type) {
    case Constants.CREATE_PENDING:
      return true
    case Constants.CREATE_SUCCESS:
    case Constants.CREATE_FAILURE:
      return false
    default:
      return state
  }
}

const isSubmittingForReview = (state = false, action) => {
  switch (action.type) {
    case Constants.SUBMIT_FOR_REVIEW_PENDING:
      return true
    case Constants.SUBMIT_FOR_REVIEW_SUCCESS:
    case Constants.SUBMIT_FOR_REVIEW_FAILURE:
      return false
    default:
      return state
  }
}

const isSubmittingToManufacturer = (state = false, action) => {
  switch (action.type) {
    case Constants.SUBMIT_TO_MANUFACTURER_PENDING:
      return true
    case Constants.SUBMIT_TO_MANUFACTURER_SUCCESS:
    case Constants.SUBMIT_TO_MANUFACTURER_FAILURE:
      return false
    default:
      return state
  }
}
const isRemoving = (state = false, action) => {
  switch (action.type) {
    case Constants.REMOVE_PENDING:
      return true
    case Constants.REMOVE_SUCCESS:
    case Constants.REMOVE_FAILURE:
      return false
    default:
      return state
  }
}

const isDuplicating = (state = false, action) => {
  switch (action.type) {
    case Constants.DUPLICATE_PENDING:
      return true
    case Constants.DUPLICATE_SUCCESS:
    case Constants.DUPLICATE_FAILURE:
      return false
    default:
      return state
  }
}

export default combineReducers({
  byId,
  search,
  paginated,
  isLoading,
  isCreating,
  isUpdating,
  isSubmittingForReview,
  isSubmittingToManufacturer,
  isRemoving,
  isDuplicating,
})
