import axios from 'axios'

const xhr = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000'
      : 'https://damp-beach-23575.herokuapp.com',
})

export default xhr
