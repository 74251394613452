import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Container from 'components/Container'
import SectionHeader, { Section } from 'components/common/SectionHeader'
import ClientSearch from 'components/ClientSearch'
import PaginationControls from 'components/PaginationControls'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Avatar from 'react-avatar'
import { Actions as ClientActions } from 'state/clients'
import { get } from 'lodash'
import moment from 'moment'

class ClientSearchPage extends React.Component {
  componentDidMount() {
    this.props.loadAll()
  }

  handleNextPage = () => {
    this.props.loadAll({ page: this.props.nextPage })
  }

  handlePrevPage = () => {
    this.props.loadAll({ page: this.props.prevPage })
  }

  render() {
    const { clients, page, pageCount, hasNextPage, hasPrevPage } = this.props

    return (
      <Container>
        <Section>
          <SectionHeader title="Clients" border={false} />
          <ClientSearch />
        </Section>
        <Section>
          <List className="borderTop">
            {clients.map(client => (
              <ListItem
                button
                component={Link}
                to={`/clients/${client.id}`}
                key={client.id}
                divider>
                <ListItemAvatar>
                  <Avatar
                    className="avatar"
                    name={client.full_name}
                    src={client.avatar}
                    size={100}
                    color="#D8D8D8"
                    fgColor="black"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={client.full_name}
                  disableTypography
                  secondary={
                    <div>
                      <div>{client.email}</div>
                      <div>{moment(client.created_at).format('MM/DD/YY')}</div>
                    </div>
                  }
                />
                <ListItemSecondaryAction>
                  <ChevronRight />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Section>
        <Section>
          <PaginationControls
            onNext={this.handleNextPage}
            onPrev={this.handlePrevPage}
            hasNextPage={hasNextPage}
            hasPrevPage={hasPrevPage}
            page={page}
            pageCount={pageCount}
          />
        </Section>
      </Container>
    )
  }
}

const mapStateToProps = (state, props) => ({
  clients: state.clients.paginated.clients || [],
  page: state.clients.paginated.page,
  pageCount: state.clients.paginated.pageCount,
  nextPage: get(state.clients.paginated.nextPage, 'page'),
  prevPage: get(state.clients.paginated.prevPage, 'page'),
  hasNextPage: !!state.clients.paginated.nextPage,
  hasPrevPage: !!state.clients.paginated.prevPage,
})

const mapDispatchToProps = {
  loadAll: ClientActions.loadAll,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientSearchPage)
