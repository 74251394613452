import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { OutlinedButton } from 'components/Button'
import Container from 'components/Container'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import ImageOption from 'components/common/ImageOption'
import Input from 'components/TextInput'
import { keyBy, sortBy, last, uniqBy, first } from 'lodash'
import Sidebar from './Sidebar'
import {
  Actions as GarmentTypeActions,
  Selectors as GarmentTypeSelectors,
} from 'state/garmentTypes'

const styles = theme => ({})

const renderTextField = props => {
  const { input, ...rest } = props
  return <Input {...input} {...rest} />
}

const renderRadioOption = ({
  input,
  options,
  onClick,
  showOptionText = true,
}) => {
  const sortedOptions = sortBy(options, 'order')
  return (
    <Grid container spacing={16}>
      {sortedOptions.map(option => {
        const clickHandler = () => {
          input.onChange(option.identifier)
          if (onClick) {
            onClick()
          }
        }

        return (
          <Grid item xs={6} sm={4} lg={3} key={option.id}>
            <input
              id={option.id}
              type="radio"
              {...input}
              value={option.value}
              checked={option.value === input.value}
              style={{
                position: 'absolute',
                opacity: 0,
                cursor: 'pointer',
                height: 0,
                width: 0,
              }}
            />
            <ImageOption
              imageUrl={option.image}
              display={showOptionText ? option.display : null}
              isActive={option.identifier === input.value}
              onClick={clickHandler}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

const validate = values => {
  const errors = {}
  if (!values.username) {
    errors.username = 'Required'
  } else if (values.username.length > 15) {
    errors.username = 'Must be 15 characters or less'
  }
  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }
  if (!values.age) {
    errors.age = 'Required'
  } else if (isNaN(Number(values.age))) {
    errors.age = 'Must be a number'
  } else if (Number(values.age) < 18) {
    errors.age = 'Sorry, you must be at least 18 years old'
  }
  return errors
}

class ShirtForm extends React.Component {
  state = {
    selected: 0,
    tabs: null,
  }

  componentDidMount() {
    this.props.loadGarmentTypes()
    this.setTabs(this.props.garmentOptions)
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.garmentOptions && this.props.garmentOptions) {
      this.setTabs(this.props.garmentOptions)
    }
  }

  setTabs = garmentOptions => {
    if (!garmentOptions) return
    const tabs = sortBy(
      uniqBy(garmentOptions.garment_options, 'order'),
      'order',
    )
    this.setState({ tabs })
  }

  handleChange = selected => {
    this.setState({ selected })
  }

  handleOptionChange = index => {
    this.setState(prevState => ({
      option: index === prevState.option ? null : index,
    }))
  }

  handleNext = () =>
    this.setState(prevState => ({
      selected: prevState.selected + 1,
    }))

  render() {
    const { garmentOptions, formValues } = this.props
    const { tabs, selected } = this.state
    if (!tabs || !garmentOptions) return null
    const current = tabs[selected]
    const keyedOptions = keyBy(tabs, 'identifier')

    const data = keyedOptions[current.identifier]
    const isLastStep = current === last(tabs)
    const isFirstStep = current === first(tabs)
    const fabricVendor = garmentOptions.garment_options.find(
      option => option.identifier === 'fabric_vendor',
    )

    return (
      <Sidebar
        options={uniqBy(tabs, 'order')}
        garment={formValues || {}}
        keyedOptions={keyedOptions}
        selected={current.identifier}
        onChange={index => this.handleChange(index)}>
        <Container>
          <h3 className="mb3">
            {data.identifier === 'fabric_number'
              ? 'Select a Fabric'
              : data.display}
          </h3>
          <Grid container spacing={8}>
            {data.identifier === 'fabric_number' ? (
              <React.Fragment>
                <Field
                  component={renderRadioOption}
                  name="fabric_vendor"
                  options={fabricVendor.values}
                  className="mb3"
                  showOptionText={false}
                />
                <div className="mt3" style={{ width: '100%' }}>
                  <Field
                    component={renderTextField}
                    name={data.identifier}
                    fullWidth
                    placeholder="Enter a fabric number"
                    label="Fabric Number"
                  />
                </div>
              </React.Fragment>
            ) : (
              <Field
                component={renderRadioOption}
                onClick={!isLastStep && this.handleNext}
                name={data.identifier}
                options={data.values}
              />
            )}
          </Grid>
          <div className="flex justify-center mt3">
            {isFirstStep && (
              <OutlinedButton
                onClick={this.handleNext}
                size="large"
                style={{ width: 150 }}>
                Next
              </OutlinedButton>
            )}
          </div>
        </Container>
      </Sidebar>
    )
  }
}

const mapStateToProps = (state, props) => ({
  initialValues: props.order.garment,
  garmentOptions: GarmentTypeSelectors.getShirtOptions(state),
})

const mapDispatchToProps = {
  loadGarmentTypes: GarmentTypeActions.load,
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm({
      form: 'buildGarment',
      validate,
    })(ShirtForm),
  ),
)
