import { connect } from 'react-redux'
import {
  Actions as OrderActions,
  Selectors as OrderSelectors,
} from 'state/orders'

const mapStateToProps = (state, props) => ({
  orders: OrderSelectors.getAllForClient(state, Number(props.client.id)),
})

const mapDispatchToProps = {
  loadOrders: OrderActions.loadAll,
  createOrder: OrderActions.create,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)
