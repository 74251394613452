import { connect } from 'react-redux'
import { Selectors as UISelectors, Actions as UIActions } from 'state/ui'
import { Actions as OrderActions } from 'state/orders'

const mapStateToProps = (state, props) => ({
  isOpen: UISelectors.getIsEditNotesOpen(state),
})

const mapDispatchToProps = {
  onClose: UIActions.closeEditNotes,
  updateOrder: OrderActions.update,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)
