import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
  palette: {
    primary: {
      main: '#212121',
    },
    secondary: {
      main: '#874242',
    },
    error: {
      main: '#B71C1C',
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 0,
        padding: '12px 16px',
        fontWeight: 'bold',
        letterSpacing: '1.5px',
        // border: '2px solid black',
      },
      contained: {
        boxShadow: 'none',
      },
      sizeLarge: {
        padding: '16px 24px',
      },
      outlinedPrimary: {
        border: '2px solid black',
        '&:hover': {
          border: '2px solid black',
        },
      },
      containedPrimary: {
        border: '2px solid black',
        '&:hover': {
          border: '2px solid black',
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          background: 'transparent',
        },
      },
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      '"Montserrat"',
      // '"PT Serif"',
      '"Helvetica Neue"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    // h4: {
    //   fontFamily: 'Roboto',
    // },
  },
})
