import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Field, reduxForm } from 'redux-form'
import Input from './Input'
import ImageUploader from 'components/ImageUploader'
import TextField from 'components/TextInput'
import { allowanceMap, baseFields } from './data'
import FormControl from '@material-ui/core/FormControl'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Section } from 'components/common/SectionHeader'
import { omit } from 'lodash'
import {
  Actions as MeasurementActions,
  Selectors as MeasurementSelectors,
} from 'state/measurements'

const styles = theme => ({
  label: {
    color: theme.palette.secondary.main,
  },
})

const getLabel = field => {
  const parts = field.split('_')
  parts.pop()
  return parts.join(' ')
}

const TextInput = ({ input, ...rest }) => (
  <FormControl margin="normal" fullWidth>
    <TextField {...input} {...rest} />
  </FormControl>
)

const CustomTextInput = ({ input, ...rest }) => (
  <FormControl margin="normal" fullWidth>
    <Input {...input} {...rest} />
  </FormControl>
)

class Form extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.fitting !== this.props.fitting) {
      this.updateAllFinishedValues()
    }
  }

  updateAllFinishedValues = () => {
    const {
      fieldValues: { values },
    } = this.props
    if (!values) return null
    const tapedFields = Object.keys(
      this.props.fieldValues.registeredFields,
    ).filter(field => field.match(/taped/))

    tapedFields.forEach(field => {
      this.applyFinishedAllowance(field, this.props.fieldValues.values[field])
    })
  }

  handleTapedValueChange = (evt, newValue, prevValue, name) => {
    this.applyFinishedAllowance(name, newValue)
  }

  applyFinishedAllowance = (field, value) => {
    if (!value) return
    const mappedField = field.replace('taped', 'finished')
    const allowances = allowanceMap[field]
    this.props.change(
      mappedField,
      (Number(value) + allowances[this.props.fitting]).toString(),
    )
  }

  handleTabChange = (_, tab) => {
    this.props.onTabChange(tab)
  }

  render() {
    const { fitting } = this.props
    return (
      <React.Fragment>
        <Section>
          <Grid container justify="space-between" spacing={16}>
            <Grid
              container
              alignItems="flex-end"
              item
              xs={12}
              sm={6}
              spacing={16}>
              <Grid item xs={12} sm={6}>
                <Field
                  autoFocus
                  name="height_feet"
                  label="Height"
                  placeholder="Feet"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="height_inches"
                  placeholder="Inches"
                  component={TextInput}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="weight_pounds"
                label="Weight"
                placeholder="Weight"
                component={TextInput}
              />
            </Grid>
          </Grid>
        </Section>
        <Tabs
          value={fitting}
          onChange={this.handleTabChange}
          variant="fullWidth">
          <Tab label="Slim" value="slim" />
          <Tab label="Regular" value="regular" />
          <Tab label="Classic" value="classic" />
        </Tabs>
        <Section>
          <Grid container justify="space-between" spacing={16}>
            {baseFields.map((baseField, index) => (
              <Grid item xs={12} sm={6} key={baseField}>
                <div key={index} className="mb3">
                  <Field
                    component={CustomTextInput}
                    label={getLabel(`${baseField}_taped`)}
                    name={`${baseField}_taped`}
                    type="number"
                    placeholder="Taped measurement"
                    onChange={this.handleTapedValueChange}
                  />
                  <Field
                    component={CustomTextInput}
                    name={`${baseField}_finished`}
                    type="number"
                    placeholder="Finished measurement"
                  />
                </div>
              </Grid>
            ))}
          </Grid>
          <div>
            <h1>Images</h1>
            <Grid container>
              <Grid item xs={4} md={3}>
                <Field
                  label="Attach Front"
                  name="front_image"
                  component={ImageUploader}
                />
              </Grid>
              <Grid item xs={4} md={3}>
                <Field
                  label="Attach Side"
                  name="side_image"
                  component={ImageUploader}
                />
              </Grid>
              <Grid item xs={4} md={3}>
                <Field
                  label="Attach Back"
                  name="back_image"
                  component={ImageUploader}
                />
              </Grid>
              <Grid item xs={4} md={3}>
                <Field
                  label="Attach Additional"
                  name="additional_image"
                  component={ImageUploader}
                />
              </Grid>
            </Grid>
          </div>
        </Section>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, props) => ({
  fieldValues: state.form.measurements,
  fitting: MeasurementSelectors.getCurrentTab(state),
  initialValues: {
    ...omit(props.measurements, 'id', 'client_id', 'created_at', 'updated_at'),
    front_image: props.order.front_image,
    side_image: props.order.side_image,
    back_image: props.back_image,
    additional_image: props.additional_image,
  },
})

const mapDispatchToProps = {
  onTabChange: MeasurementActions.requestSetTab,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'measurements',
    pure: false,
  })(withStyles(styles)(Form)),
)
