import * as Constants from './constants'

export const load = () => ({
  type: Constants.LOAD,
})

export const loadSuccess = ({ garmentTypes }) => ({
  type: Constants.LOAD_SUCCESS,
  payload: {
    garmentTypes,
  },
})

export const loadFailure = error => ({
  type: Constants.LOAD_FAILURE,
  payload: {
    error,
  },
})
