import { connect } from 'react-redux'
import { get } from 'lodash'
import {
  Actions as OrderActions,
  // Selectors as OrderSelectors,
} from 'state/orders'

const mapStateToProps = (state, props) => ({
  orders: state.orders.paginated.orders || [],
  page: state.orders.paginated.page,
  pageCount: state.orders.paginated.pageCount,
  nextPage: get(state.orders.paginated.nextPage, 'page'),
  prevPage: get(state.orders.paginated.prevPage, 'page'),
  hasNextPage: !!state.orders.paginated.nextPage,
  hasPrevPage: !!state.orders.paginated.prevPage,
})

const mapDispatchToProps = {
  loadOrders: OrderActions.loadAll,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)
