import xhr from 'xhr'

export const loadOrder = id =>
  xhr({
    method: 'get',
    url: `/orders/${id}`,
  })

export const loadOrders = (query = {}) =>
  xhr({
    method: 'get',
    url: `/orders`,
    params: query,
  })

export const createOrder = data =>
  xhr({
    method: 'post',
    url: `/orders`,
    data,
  })

export const updateOrder = (orderId, data) =>
  xhr({
    method: 'put',
    url: `/orders/${orderId}`,
    data,
  })

export const submitOrderForReview = orderId =>
  xhr({
    method: 'put',
    url: `/orders/${orderId}/ready_for_review`,
  })

export const submitOrderToManufacturer = orderId =>
  xhr({
    method: 'put',
    url: `/orders/${orderId}/submit`,
  })

export const removeOrder = orderId =>
  xhr({
    method: 'delete',
    url: `/orders/${orderId}`,
  })

export const duplicateOrder = orderId =>
  xhr({
    method: 'post',
    url: `/orders/${orderId}/duplicate`,
  })
