import React from 'react'
import ShirtForm from 'components/ShirtForm'
import ActionSheet from 'components/common/ActionSheet'

const required = [
  'fabric_number',
  'placket',
  'pocket',
  'collar',
  'cuff',
  'fabric_vendor',
  'darts',
  'yoke',
  'bottoms',
]

class BuildGarmentPage extends React.Component {
  handleSubmit = () => {
    const { formValues, order } = this.props
    const shouldWarn = required.some(key => !formValues[key])

    this.props.createGarment({
      orderId: order.id,
      params: formValues,
      shouldWarn,
    })
  }

  handleSave = () => {
    this.props.updateOrder({
      orderId: this.props.order.id,
      params: {
        garment: this.props.formValues,
      },
      transitionTo: `/clients/${this.props.client.id}/orders/${
        this.props.order.id
      }`,
    })
  }

  handleCancel = () => {
    this.props.cancelOrder(this.props.order.id)
  }

  render() {
    const { order, formValues = {} } = this.props
    if (!order) return null

    return (
      <div>
        <ShirtForm order={order} formValues={formValues} />
        <ActionSheet
          primaryText="Review"
          primaryProps={{}}
          onSecondary={this.handleSave}
          secondaryText="Save Changes"
          onPrimary={this.handleSubmit}
          cancelText="Cancel Order"
          onCancel={this.handleCancel}
        />
      </div>
    )
  }
}

export default BuildGarmentPage
