import xhr from './xhr'
import { call } from 'redux-saga/effects'

export default function* send(config) {
  try {
    const auth = {
      'access-token': localStorage.getItem('access-token'),
      client: localStorage.getItem('client'),
      expiry: localStorage.getItem('expiry'),
      'token-type': localStorage.getItem('token-type'),
      uid: localStorage.getItem('uid'),
    }
    const result = yield call(xhr, {
      ...config,
      headers: {
        ...(config.headers || {}),
        ...auth,
      },
    })
    return result
  } catch (e) {
    if (e.response.status === 401) {
      console.warn('unauthorized')
    } else {
      throw e
    }
  }
}
