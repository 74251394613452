import { combineReducers } from 'redux'
import { keyBy } from 'lodash'
import * as Constants from './constants'

const byType = (state = {}, action) => {
  switch (action.type) {
    case Constants.LOAD_SUCCESS:
      return {
        ...state,
        ...keyBy(action.payload.garmentTypes, 'identifier'),
      }
    default:
      return state
  }
}

const isLoading = (state = false, action) => {
  switch (action.type) {
    case Constants.LOAD:
      return true
    case Constants.LOAD_SUCCESS:
    case Constants.LOAD_FAILURE:
      return false
    default:
      return state
  }
}

export default combineReducers({
  byType,
  isLoading,
})
