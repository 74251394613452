import { connect } from 'react-redux'
import { Actions as ClientActions } from 'state/clients'

const mapStateToProps = state => ({})

const mapDispatchToProps = {
  createClient: ClientActions.create,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)
