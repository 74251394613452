import { connect } from 'react-redux'
import { Actions as ClientActions } from 'state/clients'

const mapDispatchToProps = {
  updateClient: ClientActions.update,
}

export default connect(
  null,
  mapDispatchToProps,
)
