import React from 'react'
import { connect } from 'react-redux'
import {
  Actions as OrderActions,
  Selectors as OrderSelectors,
} from 'state/orders'
import {
  Actions as ClientActions,
  Selectors as ClientSelectors,
} from 'state/clients'
import Container from 'components/Container'
import ActionLink from 'components/common/ActionLink'
import Grid from '@material-ui/core/Grid'
import MeasurementSummary from 'components/MeasurementSummary'
import SectionHeader, { Section } from 'components/common/SectionHeader'
import ActionSheet from 'components/common/ActionSheet'
import EditShirtModal from 'components/EditShirtModal'
import { Actions as UIActions } from 'state/ui'

const mapStateToProps = (state, { match }) => ({
  clientId: match.params.id,
  client: ClientSelectors.getMember(state, match.params.id),
  orders: OrderSelectors.getUnsubmittedOrders(state, match.params.id),
})

const mapDispatchToProps = {
  loadClient: ClientActions.load,
  loadOrders: OrderActions.loadAll,
  removeOrder: OrderActions.remove,
  duplicateOrder: OrderActions.duplicate,
  submitOrders: OrderActions.submitOrdersForReview,
  openEditShirt: UIActions.openEditShirt,
}

const GarmentOptions = ({ garment }) => {
  return (
    <Grid container>
      {garment.map((option, index) => (
        <Grid item xs={6} sm={4} md={3} key={index}>
          <h3 className="mb1">{option.option_display}</h3>
          <p>{option.value_display}</p>
          {option.image && (
            <div
              className="flex items-center justify-center"
              style={{ border: '1px solid black', height: 75, width: 75 }}>
              <img
                src={option.image}
                alt={option.value_display}
                height={50}
                width={50}
              />
            </div>
          )}
        </Grid>
      ))}
    </Grid>
  )
}

class CartPage extends React.Component {
  state = {
    editingOrder: null,
  }

  componentDidMount() {
    this.props.loadClient(this.props.clientId)
    this.props.loadOrders({ clientId: this.props.clientId })
  }

  handleRemove = orderId => {
    this.props.removeOrder(orderId)
  }

  handleDuplicate = orderId => {
    this.props.duplicateOrder(orderId)
  }

  handleSubmit = () => {
    this.props.submitOrders(this.props.orders)
  }

  handleEditShirt = order => {
    this.setState({ editingOrder: order })
    this.props.openEditShirt()
  }

  render() {
    const { orders, client } = this.props
    if (!client) return null
    const fitType = orders[0] ? orders[0].fitting_type : null

    return (
      <div style={{ marginBottom: 160 }}>
        <EditShirtModal order={this.state.editingOrder} />
        <Container className="mb3">
          <SectionHeader title="Your Cart" border={false} />
        </Container>
        {orders.length ? (
          <React.Fragment>
            <Container>
              <Section>
                <SectionHeader title="Measurements & Fit" subheader />
                <MeasurementSummary
                  measurements={client.measurements}
                  showFinished
                  fitType={fitType}
                />
              </Section>
              {orders.map((order, index) => (
                <React.Fragment key={order.id}>
                  <Section key={order.id}>
                    <SectionHeader title={`Shirt ${index + 1}`} subheader>
                      <ActionLink onClick={() => this.handleRemove(order.id)}>
                        Remove
                      </ActionLink>
                      <ActionLink
                        onClick={() => this.handleDuplicate(order.id)}
                        padded>
                        Duplicate
                      </ActionLink>
                      <ActionLink
                        onClick={() => this.handleEditShirt(order)}
                        padded>
                        Edit
                      </ActionLink>
                    </SectionHeader>
                  </Section>
                  {order.garment && (
                    <Section>
                      <GarmentOptions garment={order.garment_summary} />
                    </Section>
                  )}
                  <Section>
                    <h3 className="mb1">Notes: </h3>
                    <p>{order.notes || 'None'}</p>
                  </Section>
                </React.Fragment>
              ))}
            </Container>
            <ActionSheet
              primaryText="Submit Order"
              onPrimary={this.handleSubmit}
              secondaryText="Cancel"
              onSecondary={this.handleCancel}
            />
          </React.Fragment>
        ) : (
          <Container>
            <Section>
              <SectionHeader
                title="No Orders To Submit"
                subheader
                border={false}
              />
            </Section>
          </Container>
        )}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CartPage)
