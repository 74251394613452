import { connect } from 'react-redux'
import {
  Actions as OrderActions,
  Selectors as OrderSelectors,
} from 'state/orders'

const mapStateToProps = state => ({
  submittedOrders: OrderSelectors.getSubmitted(state),
  reviewOrders: OrderSelectors.getReview(state),
  openOrders: OrderSelectors.getOpen(state),
})

const mapDispatchToProps = {
  loadOrders: OrderActions.loadDashboard,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)
