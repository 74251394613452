import React from 'react'
import Button from '@material-ui/core/Button'

const PrimaryButton = props => (
  <Button variant="contained" color="primary" size="large" {...props}>
    {props.children}
  </Button>
)

const OutlinedButton = props => (
  <Button color="primary" variant="outlined" size="large" {...props}>
    {props.children}
  </Button>
)

export { PrimaryButton, OutlinedButton }
