import React from 'react'
import Container from 'components/Container'
import ClientFields from 'components/ClientForm'
import { Section } from 'components/common/SectionHeader'
import { ActionSheetContainer } from 'components/common/ActionSheet'
import { PrimaryButton, OutlinedButton } from 'components/Button'
import { withRouter } from 'react-router-dom'
import { reduxForm } from 'redux-form'
import { pick } from 'lodash'

const CustomerForm = ({ handleSubmit, onCancel }) => (
  <form onSubmit={handleSubmit}>
    <Section>
      <ClientFields />
    </Section>
    <ActionSheetContainer>
      <OutlinedButton size="large" onClick={onCancel}>
        Cancel
      </OutlinedButton>
      <PrimaryButton size="large" type="submit" style={{ marginLeft: '1em' }}>
        Save
      </PrimaryButton>
    </ActionSheetContainer>
  </form>
)

const ClientForm = reduxForm({
  form: 'customer',
})(CustomerForm)

class EditClientPage extends React.Component {
  handleSubmit = (values, dispatch, props) => {
    const { client } = this.props
    const dirty = Object.keys(values).filter(
      key => values[key] !== props.initialValues[key],
    )
    const params = pick(values, dirty)

    this.props.updateClient({
      clientId: client.id,
      transitionTo: `/clients/${client.id}`,
      params,
    })
  }

  handleCancel = () => {
    this.props.history.goBack()
  }

  render() {
    return (
      <Container>
        <h1>Edit Client Profile</h1>
        <ClientForm
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
          initialValues={this.props.client}
        />
      </Container>
    )
  }
}

export default withRouter(EditClientPage)
