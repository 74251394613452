import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import SignOutIcon from '@material-ui/icons/ExitToApp'
import AccountIcon from '@material-ui/icons/AccountCircle'
import { getFullName } from 'utils/helpers'

const styles = {
  user: {
    fontWeight: 'bold',
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
}

class TemporaryDrawer extends React.Component {
  render() {
    const { classes, currentUser } = this.props

    const sideList = (
      <div className={classes.list}>
        <List>
          <ListItem className={classes.user}>
            {currentUser && getFullName(currentUser)}
          </ListItem>
          <ListItem component={Link} to="/clients/search">
            <ListItemIcon>
              <AccountIcon />
            </ListItemIcon>
            <ListItemText primary="Clients" style={{ textTransform: 'none' }} />
          </ListItem>
          <ListItem button onClick={this.props.onSignOut}>
            <ListItemIcon>
              <SignOutIcon />
            </ListItemIcon>
            <ListItemText primary="Sign Out" />
          </ListItem>
        </List>
      </div>
    )

    return (
      <div>
        <Drawer open={this.props.isOpen} onClose={this.props.onClose}>
          <div
            tabIndex={0}
            role="button"
            onClick={this.props.onClose}
            onKeyDown={this.props.onClose}>
            {sideList}
          </div>
        </Drawer>
      </div>
    )
  }
}

TemporaryDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(TemporaryDrawer)
