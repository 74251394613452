import * as Constants from './constants'
import * as Actions from './actions'
import { take, race, all, put } from 'redux-saga/effects'

export function* confirmSaga({ message, title, primaryText, secondaryText }) {
  yield put(
    Actions.showConfirmation({ message, title, primaryText, secondaryText }),
  )
  const { yes } = yield race({
    yes: take(Constants.CONFIRM_YES),
    no: take(Constants.CONFIRM_NO),
  })
  yield put(Actions.closeConfirmation())
  return !!yes
}

export default function* root() {
  yield all([])
}
