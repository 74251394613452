import React from 'react'
import cx from 'classnames'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  card: {
    border: `1px solid black`,
    height: 260,
    width: '100%',
    maxWidth: 200,
    display: 'flex',
    flexDirection: 'column',
  },
  cardActive: {
    border: `3px solid ${theme.palette.secondary.main}`,
  },
  selectable: {
    cursor: 'pointer',
  },
})

const ImageOption = ({ onClick, isActive, classes, imageUrl, display }) => (
  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
    <div
      onClick={onClick}
      className={cx(
        classes.card,
        isActive && classes.cardActive,
        onClick && classes.selectable,
      )}>
      <div style={{ flex: 1, padding: '1em', display: 'flex' }}>
        {imageUrl && (
          <img
            src={imageUrl}
            alt={display}
            style={{
              objectPosition: 'center',
              objectFit: 'contain',
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          />
        )}
      </div>
      {display && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderTop: '1px solid grey',
          }}>
          <h4
            style={{
              margin: 0,
              padding: 8,
              fontWeight: 400,
              textAlign: 'center',
            }}>
            {display}
          </h4>
        </div>
      )}
    </div>
  </div>
)

export default withStyles(styles)(ImageOption)
